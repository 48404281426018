<template>
    <div style="margin-top:30px;background:#FFFFFF;height: 100%;">
        <el-aside width="300px">
        <div class="head-portrait-box">
            <img class="head-portrait" :src="userInfo.headPic" alt="">
            <div class="head-portrait-text">{{userInfo.nickName}}</div>
        </div>
        <div>
            <el-menu
                    default-active="1"
                    class="el-menu-vertical-demo"
                    @open="handleOpen"
                    @close="handleClose">
                <div v-for="(item,index) in menuList" :key="index">
                    <el-menu-item :index="item.id" @click="dealNavLink(item._key,item.id)">
                        <span slot="title">{{item.title}}</span>
                    </el-menu-item>
                </div>
            </el-menu>
        </div>
        
    </el-aside>
    </div>
    
</template>

<script>
import menuConfig from "../config/menuConfig"
import request from '../../../api/index'
export default {
    name:"left-aside.vue",
    data(){
        return{
            menuList:menuConfig,
            userInfo:'',
        }
    },
    mounted() {
       this.dealNavLink("/myBill") 
       this.getUserInfo()
    },
    
    methods:{
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },

        // 点击菜单，实现路由切换
        dealNavLink(activepath){
            this.$router.push(activepath)
        },
        getUserInfo(){
            let params = {
                "userId" : JSON.parse(localStorage.getItem('userId'))
            }
            request.get('/operating-real-estate/user/getUserInfo',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.userInfo = res.data
                            }
                        }
                    );
        }
    }
}
</script>
<style lang="less" scoped>
    .head-portrait-box{
        text-align: center;
        border: 1px solid #E7E7E7;;
    }
    .head-portrait{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin: 40px 0px 20px 0px;
    }
    .head-portrait-text{
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 16px;
        margin-bottom: 39px;
    }
    .el-aside{
        height: 88vh !important;
    }
    .el-menu-item{
        text-align: center;
    }
    





</style>