import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    // State提供唯一的公共数据源，所有共享的数据都要统一放到Store的State中进行存储
      name:"",
    },
   
    mutations: {
    // 只有 mutations里的函数，才有权利修改 state 的数据
        changeName(state,newName){
        // 变更状态
        state.name=newName;
      },
    },
    actions: {
    // Actions用于处理异步任务
    },
    modules: {
    }
  })
 