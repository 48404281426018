<template>
    <div class="park-all">
        <div class="park-details-header">
            <Header :isActive="4"></Header>
        </div>
        <div class="introduce-box">
            <div class="introduce-box-top">
                <div class="int-box-top-left">
                    <div>
                        <img class="int-box-top-img" :src="parkInfo.industryPic" alt="">
                    </div>
                    <div class="int-box-top-left-bottom">
                        <div class="top-left-bottom-left">
                            <div style="margin:20px 0px">
                                <img style="margin-right:5px" src="../../assets/industrialParkDetails/Mask (9).png" alt="">
                                <span>{{parkInfo.location}}</span>
                            </div>
                            <div>
                                <img style="margin-right:5px" src="../../assets/industrialParkDetails/Clip 2 (1).png" alt="">
                                <span>{{parkInfo.work}}</span>
                            </div>
                        </div>
                        <div class="top-left-bottom-right">
                            <div class="top-left-bottom-right-box">
                                <img style="margin-right:5px" src="../../assets/industrialParkDetails/Clip 2 (2).png" alt="">
                                <span>{{parkInfo.phone}}</span>
                            </div>  
                        </div>
                    </div>
                </div> 
                <div class="int-box-top-right">
                    <div class="little-box"></div>
                    <div class="int-box-top-right-title">{{parkInfo.industryName}}</div>
                    <div class="int-box-top-right-txt" style="overflow: auto;" v-html="parkInfo.details"></div>
                </div>
            </div>
            <div class="introduce-box-bottom">
                <div class="ceshi" v-for="(item,index) in touristList" 
                 v-bind:class="{active:index==isActive}"
                 @click="checkedItem(index)">{{item.name}}</div>
            </div>
        </div>
        <div >
            <div v-if="divShow == 0" class="park-show-box">
                <ul class="part-three-ul">
                    <li v-for="item,ind in 4" class="part-three-li" style="margin-top:40px">
                        <router-link class="link" :to="{path:'/rentShopsDetails'}">
                            <div style="height:100%;position: relative;" >
                                <div class="link-first-box" >
                                    <img class="part-three-li-img" src="../../assets/home/shop.png" alt="">
                                    <div class="auction-tips1">正在进行</div>
                                    <!-- <div class="auction-tips2">即将开始</div> -->
                                    <!-- <div class="auction-tips3">已结束</div> -->
                                </div>
                                <div class="link-second-box">
                                    <div class="link-second-box-one">营业商铺10{{ind}}室</div>
                                    <div class="rowLine">
                                        <span class="area-text">面积</span>
                                        <span class="area-number">{{randomFormat(80,300)}} ㎡</span>
                                    </div>
                                    <div class="rowLine">
                                        <div class="describe">当前价格</div>
                                        <div style="display: flex;"> 
                                            <div class="price m-r-5" style="float: none;">{{randomFormat(800,10000)}}</div>
                                            <div class="company">元/月</div>
                                        </div>
                                    </div>
                                    <div class="rowLine">
                                        <span class="time-text">截止时间</span>
                                        <span class="time-number">4月28日 21:00</span>
                                    </div>
                                    <div class="rowLine tplLine">
                                        <div class="watch"><span class="sign-text m-r-5">{{randomFormat(5,100)}}</span>次围观</div>
                                        <div class="sign"><span class="sign-text m-r-5">{{randomFormat(5,100)}}</span>人报名</div>
                                    </div>
                                </div>
                                
                            </div>
                        </router-link>
                    </li>    
                </ul>
            </div>

            <div v-if="divShow == 1" class="park-show-box">
                <ul class="part-three-ul">
                    <li v-for="item,ind in 4" class="part-three-li" style="margin-top:40px">
                        <router-link class="link" :to="{path:'/rentShopsDetails'}">
                            <div style="height:100%;position: relative;">
                                <div class="link-first-box" >
                                    <img class="part-three-li-img" src="../../assets/home/shop.png" alt="">
                                    <div class="auction-tips1">正在进行</div>
                                    <!-- <div class="auction-tips2">即将开始</div> -->
                                    <!-- <div class="auction-tips3">已结束</div> -->
                                </div>
                                <div class="link-second-box">
                                    <div class="link-second-box-one">营业商铺11{{ind}}室</div>
                                    <div class="rowLine">
                                        <span class="area-text">面积</span>
                                        <span class="area-number">{{randomFormat(80,300)}} ㎡</span>
                                    </div>
                                    <div class="rowLine">
                                        <div class="describe">当前价格</div>
                                        <div style="display: flex;"> 
                                            <div class="price m-r-5" style="float: none;">{{randomFormat(800,10000)}}</div>
                                            <div class="company">元/月</div>
                                        </div>
                                    </div>
                                    <div class="rowLine">
                                        <span class="time-text">截止时间</span>
                                        <span class="time-number">4月28日 21:00</span>
                                    </div>
                                    <div class="rowLine tplLine">
                                        <div class="watch"><span class="sign-text m-r-5">{{randomFormat(5,100)}}</span>次围观</div>
                                        <div class="sign"><span class="sign-text m-r-5">{{randomFormat(5,100)}}</span>人报名</div>
                                    </div>
                                </div>
                                
                            </div>
                        </router-link>
                    </li>    
                </ul>
            </div>

            <div v-if="divShow == 2" class="park-show-box">
                <ul class="part-three-ul">
                    <li v-for="item,ind in 3" class="part-three-li" style="margin-top:40px">
                        <router-link class="link" :to="{path:'/rentShopsDetails'}">
                            <div style="height:100%;position: relative;">
                                <div class="link-first-box" >
                                    <img class="part-three-li-img" src="../../assets/home/shop.png" alt="">
                                    <div class="auction-tips1">即将开始</div>
                                    <!-- <div class="auction-tips2">即将开始</div> -->
                                    <!-- <div class="auction-tips3">已结束</div> -->
                                </div>
                                <div class="link-second-box">
                                    <div class="link-second-box-one">营业商铺12{{ind}}室</div>
                                    <div class="rowLine">
                                        <span class="area-text">面积</span>
                                        <span class="area-number">{{randomFormat(80,300)}} ㎡</span>
                                    </div>
                                    <div class="rowLine">
                                        <div class="describe">当前价格</div>
                                        <div style="display: flex;"> 
                                            <div class="price m-r-5" style="float: none;">{{randomFormat(800,10000)}}</div>
                                            <div class="company">元/月</div>
                                        </div>
                                    </div>
                                    <div class="rowLine">
                                        <span class="time-text">截止时间</span>
                                        <span class="time-number">4月28日 21:00</span>
                                    </div>
                                    <div class="rowLine tplLine">
                                        <div class="watch"><span class="sign-text m-r-5">{{randomFormat(5,100)}}</span>次围观</div>
                                        <div class="sign"><span class="sign-text m-r-5">{{randomFormat(5,100)}}</span>人报名</div>
                                    </div>
                                </div>
                                
                            </div>
                        </router-link>
                    </li>    
                </ul>
            </div>

            <div v-if="divShow == 3" class="park-show-box">
                <ul class="part-three-ul">
                    <li v-for="item,ind in 2" class="part-three-li" style="margin-top:40px">
                        <router-link class="link" :to="{path:'/rentShopsDetails'}">
                            <div style="height:100%;position: relative;">
                                <div class="link-first-box" >
                                    <img class="part-three-li-img" src="../../assets/home/shop.png" alt="">
                                    <div class="auction-tips1">已结束</div>
                                    <!-- <div class="auction-tips2">即将开始</div> -->
                                    <!-- <div class="auction-tips3">已结束</div> -->
                                </div>
                                <div class="link-second-box">
                                    <div class="link-second-box-one">营业商铺13{{ind}}室</div>
                                    <div class="rowLine">
                                        <span class="area-text">面积</span>
                                        <span class="area-number">{{randomFormat(80,300)}} ㎡</span>
                                    </div>
                                    <div class="rowLine">
                                        <div class="describe">当前价格</div>
                                        <div style="display: flex;"> 
                                            <div class="price m-r-5" style="float: none;">{{randomFormat(800,10000)}}</div>
                                            <div class="company">元/月</div>
                                        </div>
                                    </div>
                                    <div class="rowLine">
                                        <span class="time-text">截止时间</span>
                                        <span class="time-number">4月28日 21:00</span>
                                    </div>
                                    <div class="rowLine tplLine">
                                        <div class="watch"><span class="sign-text m-r-5">{{randomFormat(5,100)}}</span>次围观</div>
                                        <div class="sign"><span class="sign-text m-r-5">{{randomFormat(5,100)}}</span>人报名</div>
                                    </div>
                                </div>
                                
                            </div>
                        </router-link>
                    </li>    
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../../components/header.vue'
import request from '../../api/index'
export default {
    data() {
        return {
            divShow:'',
            isActive:0,
            roomId:'',
            parkInfo:'',
        touristList:[
            {
                name:"全部房产",
                id:0
            },
            {
                name:"正在进行",
                id:1
            },
            {
                name:"即将开始",
                id:2
            },
            {
                name:"已结束",
                id:3
            },
        
            ], 
        }
    },
    mounted() {
        window.scrollTo(0,0)
        this.getParkInfo()
    },
    methods: {
        randomFormat(x,y){
            return Math.round(Math.random()*(y-x)+x)
        },
        getParkInfo(){
            this.roomId = this.$route.query.roomId;
            console.log(this.roomId);
            let params = {
                    "id" : this.roomId,
                     
            }
                    request.get('/operating-real-estate/home/industrialDetails',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.parkInfo = res.data
                        }
                        }
                    );
                    
        },
      checkedItem(index){
        this.isActive=index;
        this.divShow = index;
    },
    },
    components:{
        Header,
    }
}
</script>
<style lang="less" scoped>
    .m-r-5 {
        margin-right: 5px;
    }
    .park-show-box {
        .link-first-box {
            position: relative;
            .part-three-li-img {
                border-radius: 0;
            }
            .auction-tips1 {
                position: absolute;
                bottom: 3px;
                right: 0;
            }
        }
        .link-second-box {
            background-color: #fff;
            padding-top:20px;
            color: #999;
            font-size: 14px;
            .link-second-box-one {
                color: #333;
                padding: 0 20px;
                font-size: 18px;
                margin-bottom: 20px;
            }
            .rowLine {
                display: flex;
                font-size: 14px;
                margin-bottom: 20px;
                padding: 0 20px;
                justify-content:space-between;
                .time-number,.sign-text,.area-number {
                    color: #333;
                }
                .price {
                    color: #E76565;
                }
                .company {
                    margin: 0;
                }
            }
            .tplLine {
                text-align: center;
                height: 34px;
                line-height: 34px;
                padding: 0;
                width: 100%;
                border-top:1px solid #ececec ;
                margin-bottom: 0;
                .watch {
                    border-right:1px solid #ECECEC ;
                }
                .watch,.sign {
                    width: 50%;
                }
            }
        }
    }
    .auction-tips1,.auction-tips2,.auction-tips3{
        width: 70px;
        height: 25px;
        font-size: 12px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 25px;
        text-align: center;
       
    }
    .auction-tips1{
        background-image: url(../../assets/home/underway.png);
    }
    .auction-tips2{
        background-image: url(../../assets/home/start.png);
    }
    .auction-tips3{
        background-image: url(../../assets/home/over.png);
    }
    .park-all{
        min-width: 1520px;
        height: 1800px;
        background-color: #F2F3F8;
    }
    .park-details-header{
        width:100%;
        min-width: 1520px;
        height: 270px;
        background-image: url(../../assets/industrialParkDetails/background.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .part-three-ul {
        display: flex;
        .part-three-li {
            margin-right: 20px;
        }
    }
    .introduce-box{
        width: 100%;
        height: 520px;
        background: #FFFFFF;
        padding-top: 60px;
    }
    .introduce-box-top{
        width: 1160px;
        height: 470px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;

    }
    .int-box-top-left{
        flex: 5.5;
        margin-right: 30px;
    }
    .int-box-top-img{
        width: 600px;
        height: 360px;
    }
    .int-box-top-left-bottom{
        display: flex;
        flex-direction: row;

    }
    .top-left-bottom-left{
        text-align: left;
        flex: 7;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
    }
    .top-left-bottom-right{
        flex: 3;
    }
    .top-left-bottom-right-box{
        width: 200px;
        height: 51px;
        line-height: 51px;
        background: #3094FF;
        border-radius: 8px;
        font-size: 22px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 20px;
    }
    .int-box-top-right{
        flex: 4.5;
        margin-left: 30px;
        
    }
    .little-box{
        width: 20px;
        height: 20px;
        background: #3094FF;
    }
    .int-box-top-right-title{
        text-align: left;
        height: 40px;
        font-size: 40px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        margin: 0px 0px 80px 20px;
    }
    .int-box-top-right-txt{
        width: 500px;
        max-height: 300px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        text-align: left;
    }
    .introduce-box-bottom{
        width: 1160px;
        height: 470px;
        margin: 0 auto;
        text-align: left;
    }
    .ceshi{
        color: rgb(228, 78, 9);
        display: inline-block;
        margin-right: 50px;
        // width: 72px;
        height: 18px;
        font-size: 18px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
    }
    .active{
        // width: 72px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333; 
        border-bottom: 4px solid #000000; 
        
        // background-repeat: no-repeat;
        // background-image: linear-gradient(90deg, #000 100%, #f00 100%);
        // background-size: 50% 3px;
        // background-position: 50% 100%;
        // display: inline-block;
    }
    .park-show-box{
        width: 1160px;
        margin: 0 auto;
        text-align: left;
    }
</style>