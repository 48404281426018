<template>
    <div style="min-whith:1820px;background: #F2F3F8;">
        <div class="cultural-head">
            <Header :isActive="8"></Header>
        </div>
        <div class="cultural-body">
            <ul class="cultural-ul">
                <li v-for="item in attractionList" class="cultural-li">
                    <router-link class="link" :to="{path:'/shoppingMall',query: { id: item.id}}">
                    <div class="cultural-li-box">
                        <div class="cultural-li-box-left">
                            <img class="left-img" :src="item.image" alt="">
                        </div>
                        <div class="cultural-li-box-right">
                            <div class="right-header">
                                <span class="right-header-span">详情</span>
                                <img class="right-header-img" src="../../assets/home/right.png" alt="">
                            </div>
                            <div class="right-body">{{item.title}}</div>
                            <div class="right-footer">
                                <div class="des">{{item.describe}}</div>
                                <div class="tplPrice">价格：<span class="price" style="font-size: 25px;float: none;">{{(item.price/100).toFixed(2)}}</span>元 </div>
                            </div>
                        </div>
                    </div>
                </router-link>   
                </li>
            </ul>
        </div>
        <div class="page">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="1"
                :page-sizes="[5, 10, 15, 20]"
                :page-size="5"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import Header from '../../components/header.vue'
import request from '../../api/index'
export default {
    name:'CulturalTourismList',
    data(){
        return{
           attractionList:'',
            // 总条数
            totalCount:null,
            // 页数
            pageNum:null,
            // 每页多少条
            pageRow:null,
            
        }
    },
   
    mounted() {
       this.getAttractionList()
    },
     methods: {
        getAttractionList(){
            let params = { 
                "pageNum" : this.pageNum || 1,
                "pageRow" : this.pageRow || 5,
                    }
                    request.get('/api/product/list',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.attractionList = res.data.list
                                this.totalCount = res.data.totalCount
                            }
                        }
                    ); 
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageRow = val;
            this.getAttractionList();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.getAttractionList();
        },
        
    },
    components:{
        Header,
    }
}
</script>

<style scoped lang="less">
    .cultural-head{
        width:100%;
        min-width: 1520px;
        height: 270px;
        background-image: url(../../assets/industrialParkDetails/lvyou.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-bottom: 60px;
    }
    
    .cultural-li-box{
        width: 1160px;
        height: 240px;
        margin: 0px auto;
        margin-top: 30px;
        display: flex;
        flex-direction: row;
        background: #FFFFFF;
        border-radius: 8px;
    }
    .cultural-li-box-left{
        flex: 3.5;
    }
    .left-img{
        width: 100%;
        height: 240px;
    }
    .cultural-li-box-right{
        position: relative;
        flex: 6.5;

    }
    .right-header{
        text-align: right;
        margin-top: 25px;
        padding-right: 29px;
    }
    .right-header-span{
        width: 32px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        vertical-align: middle;
    }
    .right-header-img{
        vertical-align: middle;
    }
    .right-body{
        text-align: left;
        padding-left: 60px;
        height: 40px;
        font-size: 25px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 40px;
        margin-bottom: 20px;
    }
    .right-footer{
        padding: 0px 42px 0px 60px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        display: flex;/*作为弹性伸缩盒子模型显示*/
        flex-direction: column;
        .des{
            text-align: justify;
            max-width: 660px;
            line-height: 26px;
            display: -webkit-box;
            -webkit-line-clamp: 3; /*显示的行数；如果要设置2行加...则设置为2*/
            overflow: hidden; /*超出的文本隐藏*/
            text-overflow: ellipsis; /* 溢出用省略号*/
            -webkit-box-orient: vertical;/*伸缩盒子的子元素排列：从上到下*/
        }
        .tplPrice {
            position: absolute;
            right: 25px;
            bottom: 25px;
            line-height: 20px;
            .price {
                color: red;
            }
        }
    }
    .page{
        width: 1160px !important;
        text-align: right;
        margin: 0 auto;
        height: 60px;
        background: #F2F3F8;
        margin-top: 30px;
    }
</style>