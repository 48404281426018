<template>
    <div class="park-all">
        <div class="park-details-header">
            <Header :isActive="8"></Header>
        </div>
        <div class="main-box" >
            <div class="main-top">
                <div class="content-top">
                    <img class="content-left-img"  :src="parkInfo.image" alt="">
                    <div class="content-top-right">
                        <div class="content-title">{{parkInfo.title}}</div>
                        <p class="content-txt">{{parkInfo.describe}}</p>
                        <div class="content-info">
                            <p class="goods-attr"><span class="menony">￥{{(parkInfo.price/100).toFixed(2)}}</span><i class="left"> ({{parkInfo.condition}}份起购) </i>  <i class="right">库存：{{parkInfo.stock}}</i></p> 
                            <p>
                                <el-input-number v-model="num"  :min=parkInfo.condition  label="描述文字"></el-input-number>
                                <el-button class="right" @click="sureOrder()" type="primary">立即下单</el-button>
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="content-bottom" :class="{posion:isInfo}">
                <div class="listItem">
                    <div class="ceshi" v-for="(item,index) in touristList" 
                    v-bind:class="{active:index==isActive}"
                    @click="checkedItem(index)"
                   >{{item.name}}</div>
                </div>
            </div>
            <div class="main-bottom" ref="scrollview" >
                <div class="bottom-content">
                    <div v-if="parkInfo.travel != ''" class = 'goodShow' id="0">
                        <h2>旅游路线</h2>
                        <p  v-html="parkInfo.travel"></p>
                    </div>
                    <div class = 'goodShow' v-if="parkInfo.farmProduct != ''" id="1">
                        <h2>特色农产品</h2>
                        <p  v-html="parkInfo.farmProduct"></p>
                    </div>
                    <div v-if="parkInfo.feeDetail != ''" class = 'goodShow'  id="2">
                        <h2>费用详情</h2>
                        <p v-html="parkInfo.feeDetail"></p>
                    </div>
                    <div v-if="parkInfo.activity!= ''" class = 'goodShow'  id="3">
                        <h2>活动须知</h2>
                        <p v-html="parkInfo.activity"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Header from '../../components/header.vue'
import {Loading} from 'element-ui'
import request from '../../api/index'
export default {
    data() {
        return {
            divShow:0,
            isActive:0,
            loading:null,
            isInfo:false,
            num:1,
            id:this.$route.query.id,
            parkInfo:'',
        touristList:[
            {
                name:"旅游路线",
                id:0
            },
            {
                name:"特色农产品",
                id:1
            },
            {
                name:"费用详情",
                id:2
            },
            {
                name:"活动须知",
                id:3
            },
        
            ], 
        }
    },
    mounted() {
        this.getParkInfo()
        const scrollview = this.$refs['scrollview']
        // 添加滚动监听，该滚动监听了拖拽滚动条
        // 尾部的 true 最好加上，我这边测试没加 true ，拖拽滚动条无法监听到滚动，加上则可以监听到拖拽滚动条滚动回调
        // scrollview.addEventListener('scroll', this.scrollChange, true)
        window.addEventListener("scroll", this.scrollChange,{passive:true});
    },
    beforeDestroy () {
    // 获取指定元素
        const scrollview = this.$refs['scrollview']
        // 移除监听
        // scrollview.removeEventListener('scroll', this.scrollChange, true)
        window.removeEventListener("scroll", this.scrollChange,true);
    },
    methods: {
      
        scrollChange () {
            let el = this.$refs.scrollview
            if(el){
                let rect = el.getBoundingClientRect()
                console.log(111,rect.top);
                if(rect.top<120 ){
                    this.isInfo=true
                }
                if(rect.top>0){
                    this.isInfo=false
                }
            }
        },
        getParkInfo(){
            let _that = this
            request.get(`/api/product/info/${_that.id}`)
            .then(
                res=>{
                    if (res.result == 0) {
                        if(res.data.travel!="" ){
                            _that.isActive = 0
                        }else if (res.data.farmProduct != ""){
                            _that.isActive = 1
                        }else if (res.data.feeDetail != "" ){
                            _that.isActive = 2
                         }else if(res.data.activity != "" ){
                            _that.isActive = 3
                         }else {
                            _that.isActive = 5
                         }
                         _that.num = res.data.condition
                        _that.parkInfo = res.data
                     }
                }
            );
                    
        },
        sureOrder(){
            let _that = this 
            if(JSON.parse(localStorage.getItem('userId'))){
                _that.loading = Loading.service({
                    text:'加载中....',
                    spinner:'el-icon-loading',
                    background:'rgba(0,0,0,0.7)'
                })
                let param = {
                    productId:_that.id,
                    num:_that.num,
                    userid:JSON.parse(localStorage.getItem('userId'))
                }
                request.post('/api/product_order/create_order',param)
                .then((res)=>{
                    _that.loading.close()
                    if (res.result == 0) {
                        this.$message({
                            message: '下单成功',
                            type: 'success'
                        });
                    }else {
                        this.$message({
                            message: res.message,
                            type: 'error'
                        });
                    }
                 })
            }else{
                this.$message('下单请登录，谢谢。');
            }
        },
       
        checkedItem(index){
            document.getElementById(`${index}`).scrollIntoView()
            this.isActive=index;
        },
    },
    components:{
        Header,
    }
}
</script>
<style lang="less">
    i {
       font-style: normal;
    }
    .main-bottom  img{
        width: 100%;
    }
    .goodShow {
        table {
            border-collapse: collapse;
            background: #fff;
        }
        td,th{
            border: 1px solid #ccc;
            min-height: 50px;
            height: 30px;
            padding-left: 10px;
        }
        th{
            background-color: #f1f1f1;
        }
    }
    
</style>
<style lang="less" scoped >
    .posion{
        position: fixed;
        top: 0;
        width: 100% !important;
        height: 45px !important;
        line-height: 50px;
        background: #fff;
        .listItem {
            margin-left: 30%;
        }
    }
    .goodHide {
        display: none;
    }
    .goodShow {
        text-align: left;
        display: block;
    }
    
    .park-details-header{
        width:100%;
        height: 270px;
        background-image: url(../../assets/industrialParkDetails/lvyou.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .main-box{
        color: black;
        width: 100%;
        margin: 0 auto;
        background:  #fff;
        .main-top {
            // width: 100%;
            margin: 0 auto 30px;
            width: 40%;
            min-width: 900px;
            .content-top {
                display: flex;
                padding-top: 50px;
                margin: 0 auto;
                .content-left-img {
                    width: 500px;
                    height: 300px;
                    margin: 0 20px 20px 0;
                }
                .content-top-right {
                    text-align: left;
                    position: relative;
                    width: 60%;
                    .content-title {
                        margin-bottom: 20px;
                        font-size: 24px;
                        font-weight: bold;
                    }
                    
                    .content-info {
                        font-style: normal;
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        .goods-attr {
                            vertical-align: middle;
                            line-height: 50px;
                            height: 50px;
                            i{
                                color: #999;
                            }
                            .menony{
                                font-size: 30px;
                                color: red;
                            }
                        }
                        .left{
                                margin-left: 15px;
                            }
                        .right {
                            float: right;
                            margin-right: 10px;
                        }
                        
                    }
                }
            }
            
        }
        .content-bottom {
            text-align: left;
            margin: 0 auto;
            width: 40%;
            min-width: 900px;
            font-size: 18px;
            display: flex;
            height: 30px;
        }
        .main-bottom {
            background:#F2F3F8;
            width: 100%;
            // height: 500px;
            .bottom-content{
                margin:0 auto ;
                min-height: 200px;
                // height: 1000px;
                padding: 30px 0;
                width: 800px;
            }
            
        }
    }
    .ceshi{
        font-size: 18px;
        color: rgb(228, 78, 9);
        display: inline-block;
        margin-right: 30px;
        color: #999999;
    }
    .active{
        font-size: 18px;
        line-height: 30px !important;
        height: 30px !important;
        color: #333333; 
        border-bottom: 4px solid #000000; 
    }
    
</style>