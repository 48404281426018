<template>
    <div>
        <div style="width:100%;min-width:1520px; height:70px;background:#292929 ;">
            <Header :isActive="2"></Header>
        </div>
        <div class="condition-outside-box">
            <div class="condition-inside-box">
                <div class="inside-top">
                    <div class="inside-top-left">
                        <input v-model="seachName" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" type="text" placeholder="请输入街道或大楼名称">
                        <div  class="houseSearch-btn">
                            <img @click="foundHome()" src="../../assets/rentHouseSearch/search.png" alt="">
                        </div>
                    </div>
                    <div class="inside-top-right">
                        <div class="btn-back" @click="$router.push('/rentHouseSearch')">
                            <img src="../../assets/map/Mask.png" alt="">
                            <span>查看列表</span>
                        </div>
                    </div>
                </div>
                <div class="inside-buttom">
                    <div class="inside-buttom-first">
                        <div class="ceshi" v-for="(item,index) in touristList" 
                        v-bind:class="{active:index==isActive}"
                        @click="checkedItem(item,index)">{{item.name}}</div>
                    </div>
                    <div v-if="isActive == 0" class="inside-buttom-second">
                        <div class="selection-box">
                            <span>租金：</span>
                            <el-select style="width: 150px; height:32px;" v-model="value2" placeholder="请选择" clearable @change="foundHome()">
                                <el-option
                                v-for="item in options2"
                                :key="item.value2"
                                :label="item.label2"
                                :value="item.value2">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="selection-box">
                            <span>户型：</span>
                            <el-select style="width: 150px; height:32px;" v-model="value1" placeholder="请选择" clearable @change="foundHome()">
                                <el-option
                                v-for="item in options1"
                                :key="item.value1"
                                :label="item.label1"
                                :value="item.value1">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="selection-box">
                            <span>朝向：</span>
                            <el-select style="width: 150px;height: 32px !important;" v-model="value" placeholder="请选择" clearable @change="foundHome()">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        
                        <!-- <div class="selection-box">
                            <span>装修：</span>
                            <el-select style="width: 150px;height: 32px !important;" v-model="value3" placeholder="请选择" clearable @change="foundHome()">
                                <el-option
                                v-for="item in options3"
                                :key="item.value3"
                                :label="item.label3"
                                :value="item.value3">
                                </el-option>
                            </el-select>
                        </div> -->
                    </div>
                    <div v-if="isActive == 1" class="inside-buttom-second">
                        <div class="selection-box">
                            <span>租金：</span>
                            <el-select style="width: 150px; height:32px;" v-model="value4" placeholder="请选择" clearable @change="foundHome()">
                                <el-option
                                v-for="item in options4"
                                :key="item.value4"
                                :label="item.label4"
                                :value="item.value4">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="selection-box">
                            <span>面积：</span>
                            <el-select style="width: 150px; height:32px;" v-model="value5" placeholder="请选择" clearable @change="foundHome()">
                                <el-option
                                v-for="item in options5"
                                :key="item.value5"
                                :label="item.label5"
                                :value="item.value5">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container1">   
            <div id="container"></div>  
        </div>
        <!-- 弹框 -->
            <el-dialog
                width="450px" 
                style="margin-top:80px;margin-left: 10px;" 
                :close-on-click-modal="false" 
                :visible.sync="dialogTableVisible" 
                :modal="false">
                
                 <div class="map-show-text">已为您找到
                     <!-- <span>{{ totalCount }}</span> -->
                     <span>{{this.cont}}</span>
                     套精选租房</div>
                <hr>
                <div >
                     <div style="margin-bottom:32px">
                        <ul class="map-show-nav-ul">
                            <!-- <li>综合排序</li>
                            <li @click="newHouse">最新上架</li> -->
                            <li class="map-show-nav-li-end" >
                                价格排序
                                <img @click="fromLowToHigh" v-if="sort" src="../../assets/rentHouseSearch/lower.png" alt="">
                                <img @click="fromHeightToLow" v-else src="../../assets/rentHouseSearch/up.png" alt="">
                            </li>
                        </ul>
                    </div>
                    <!-- <router-link class="link" to="/rentHouseDetails"> -->
                        <div>
                            <ul class="map-show-ul" @scroll="scrollEvent">
                                <li v-for="(item,index) in showList">
                                    <router-link class="link" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                        <!-- <div style="flex: 1;height:50px;background: red;"></div>
                                        <div style="flex: 5;height:50px;background: blue;"></div>
                                        <div style="flex: 4;height:50px;background: greenyellow;"></div> -->
                                        <div class="serial-number" v-if="index == 0"><div  class="one">{{index + 1}}</div></div>
                                        <div class="serial-number" v-else-if="index == 1"><div  class="two">{{index + 1}}</div></div>
                                        <div class="serial-number" v-else-if="index == 2"><div  class="three">{{index + 1}}</div></div>
                                        <div class="serial-number" v-else><div  class="other">{{index + 1}}</div></div>
                                        <div class="show-text">
                                            <div class="show-name">{{item.roomName}}</div>
                                            <div class="show-index">{{item.area}} / {{item.roomType}} /{{item.turnType}}</div>
                                            <div class="show-price">
                                                <div class="map-price">{{item.roomPrice}}</div>
                                                <div class="map-company">元/月</div>
                                            </div>
                                            <div class="show-ul">
                                                <ul class="map-text-end-ul">
                                                    <!-- <li v-for="i in item.trait">{{i}}</li> -->
                                                    <li>{{item.trait[0]}}</li>
                                                    <li>{{item.trait[1]}}</li>
                                                    <li>{{item.trait[2]}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div style="flex: 4;">
                                            <img style="width: 140px;height:100px;" :src="item.roomPics[0]" alt="">
                                        </div>
                                        <!-- <div style="width: 50px;height:20px;color:red;">{{index + 1}}</div>
                                        <div class="map-house-box">
                                            <img :src="item.roomPics[0]" alt="">
                                            <div class="map-house-text">
                                                <div class="map-text-first"></div>
                                                <div class="map-text-second">
                                                    <div class="map-text-second-first">
                                                        <img src="../../assets/rentHouseSearch/little-house.png" alt="">
                                                        
                                                    </div>
                                                    
                                                </div>
                                                <div class="map-text-end">
                                                    
                                                </div>
                                            </div>
                                        </div> -->
                                    </router-link>
                                    
                                </li>
                            </ul>

                            
                        </div>
                    <!-- </router-link> -->
                </div>
                
            </el-dialog>	
	
    </div>
        
</template>

<script>
// import AMap from "AMap"
import Header from '../../components/header.vue'
import request from '../../api/index'
var x_pi = 3.14159265358979324 * 3000.0 / 180.0
var pi = 3.14159265358979324;
 var a = 6378245.0;
var ee = 0.00669342162296594323;
export default {
    name: 'HelloWorld',
    data() {
        return {
            map: '',
            KEY: '86b5201d02a76c91a06dc85ced5816c0',    //将开发者账号申请的key放入
            dataList:[],
            // 当前选中的状态
            nowType:'ZF',
            dialogTableVisible:false,
            showList:[],
            roomPriceMax:'',
            roomPriceMin:'',
            minArea:'',
            maxArea:'',
            cont:null,
            sort:true,
            seachName:'',
            buildId:'',
             // 总条数
            totalCount:null,
            // 页数
            pageNum:1,
            pageNum1:'',
            // 每页多少条
            pageRow:null,
             options: [{
                value: '',
                label: '全部'
                },{
                value: 'SOUTH',
                label: '朝南'
                }, {
                value: 'NORTH',
                label: '朝北'
                }, {
                value: 'WEST',
                label: '朝西'
                }, {
                value: 'EAST',
                label: '朝东'
                }],
            value: '',
             options1: [{
                value1: '',
                label1: '全部'
                },{
                value1: '1',
                label1: '一居室'
                }, {
                value1: '2',
                label1: '二居室'
                }, {
                value1: '3',
                label1: '三居室'
                }, {
                value1: '4',
                label1: '四居室以上'
                }],
                value1: '',
            options2: [{
                value2: '',
                label2: '全部'
                },{
                value2: '1',
                label2: '<1000元'
                }, {
                value2: '2',
                label2: '1000-2000元'
                }, {
                value2: '3',
                label2: '2000-3000元'
                }, {
                value2: '4',
                label2: '3000-4000元'
                },{
                value2: '5',
                label2: '>4000元'
                }],
                value2: '',
            // options3: [{
            //     value3: '',
            //     label3: '全部'
            //     },{
            //     value3: 'HH',
            //     label3: '豪华'
            //     }, {
            //     value3: 'JZ',
            //     label3: '精装'
            //     }, {
            //     value3: 'JD',
            //     label3: '简装'
            //     }, {
            //     value3: 'MP',
            //     label3: '毛坯'
            //     }],
            //     value3: '',
            options4: [{
                value4: '',
                label4: '全部'
                },{
                value4: '1',
                label4: '<2000元'
                }, {
                value4: '2',
                label4: '2000-5000元'
                }, {
                value4: '3',
                label4: '5000-1万元'
                }, {
                value4: '4',
                label4: '1-2万元'
                }, {
                value4: '5',
                label4: '2-5万元'
                }, {
                value4: '6',
                label4: '5-10万元'
                }, {
                value4: '7',
                label4: '>10万元'
                }],
                value4: '',
            options5: [{
                value5: '',
                label5: '全部'
                },{
                value5: '1',
                label5: '<20㎡'
                }, {
                value5: '2',
                label5: '20-50㎡'
                }, {
                value5: '3',
                label5: '50-100㎡'
                }, {
                value5: '4',
                label5: '100-300㎡'
                }, {
                value5: '5',
                label5: '300-500㎡'
                }, {
                value5: '6',
                label5: '>500㎡'
                }],
                value5: '',
                isActive:0,
            touristList:[
            {
                name:"住宅",
                type:'ZF',
                id:0
            },
            {
                name:"商铺",
                type:'SP',
                id:1
            },
            {
                name:"办公用房",
                type:'BG',
                id:2
            },
            
        
            ], 

            //地图转换专用
        }
    },
     created(){
        this.getCoordinateInformation('ZF');
    },
    
     mounted() {
        
       this.draw()
        

    },
    destroyed() {
        let coreScript = document.getElementById('coreScript');
        let markerScript = document.getElementById('markerScript');
        let demoScript = document.getElementById('demoScript');

        coreScript.parentNode.removeChild(coreScript);
        markerScript.parentNode.removeChild(markerScript);
        demoScript.parentNode.removeChild(demoScript);
    },
    methods: {
        //坐标转换开始-------begin-----------
         transformLon(lon,lat) {
            const x_PI = 3.14159265358979324 * 3000.0 / 180.0
            const PI = 3.1415926535897932384626
            const a = 6378245.0
            const ee = 0.00669342162296594323
            let ret = 300.0 + lon + 2.0 * lat + 0.1 * lon * lon + 0.1 * lon * lat + 0.1 * Math.sqrt(Math.abs(lon))
            ret += (20.0 * Math.sin(6.0 * lon * PI) + 20.0 * Math.sin(2.0 * lon * PI)) * 2.0 / 3.0
            ret += (20.0 * Math.sin(lon * PI) + 40.0 * Math.sin(lon / 3.0 * PI)) * 2.0 / 3.0
            ret += (150.0 * Math.sin(lon / 12.0 * PI) + 300.0 * Math.sin(lon / 30.0 * PI)) * 2.0 / 3.0
            return ret
        },
 
        transformLat(lon,lat) {
            const x_PI = 3.14159265358979324 * 3000.0 / 180.0
            const PI = 3.1415926535897932384626
            const a = 6378245.0
            const ee = 0.00669342162296594323
            let ret = -100.0 + 2.0 * lon + 3.0 * lat + 0.2 * lat * lat + 0.1 * lon * lat + 0.2 * Math.sqrt(Math.abs(
                lon))
            ret += (20.0 * Math.sin(6.0 * lon * PI) + 20.0 * Math.sin(2.0 * lon * PI)) * 2.0 / 3.0
            ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0
            ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0
            return ret
        },
        outOfChina(lon, lat) {
            return (lon < 72.004 || lon > 137.8347) || ((lat < 0.8293 || lat > 55.8271) || false)
        },


        wgs_gcj_encrypts(lon,lat) {
            if (this.outOfChina(lon, lat)) {
                console.log('国外')
                return [lon, lat]
            }
            const x_PI = 3.14159265358979324 * 3000.0 / 180.0
            const PI = 3.1415926535897932384626
            const a = 6378245.0
            const ee = 0.00669342162296594323
            let dlat = this.transformLat(lon - 105.0, lat - 35.0)
            let dlon = this.transformLon(lon - 105.0, lat - 35.0)
            let radlat = lat / 180.0 * PI
            let magic = Math.sin(radlat)
            magic = 1 - ee * magic * magic
            let sqrtmagic = Math.sqrt(magic)
            dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI)
            dlon = (dlon * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI)
            var mglat = lat + dlat
            var mglon = lon + dlon
            return [mglon, mglat]
        },
     //坐标转换结束---------end----------

        async draw(x=119.7183025,y=30.24651){
            let that = this;
        
        try{
            await that.createMarker();
            await that.createCoreScript()
            await that.createDemo();
           

            that.map = new AMap.Map('container', {
                center: [x, y],
                resizeEnable: true,
                zoom:13
            });
            
            let markers = []
            
            let t;
            t = setTimeout(function() {
                for (var i = 0, n = that.dataList.length;  i < n; i += 1) {
                    let marker;
                    console.log(typeof that.dataList[i].cdeAddressX)
                    // let tplMarker = 
                    // console.log(1212,tplMarker);
                    if(that.dataList[i].cdeAddressX != null && that.dataList[i].cdeAddressY != null){
                        let content= `<div id = 'taiwan' 
                            style='display:flex;flex-direction: column;justify-content:center;align-items:center;color: white;width: 80px; height:80px;border-radius:50%;text-align:center;background: #3094FF;opacity: 0.8;font-weight:bold'
                        >
                            <div style='margin-bottom:6px'>${that.dataList[i].estateName}</div>
                            <div>${that.dataList[i].count}个房间</div>
                        </div>`;
                        marker = new AMap.Marker({
                            content: content,
                            position: that.wgs_gcj_encrypts(that.dataList[i].cdeAddressX,that.dataList[i].cdeAddressY),
                            title: that.dataList[i].estateName,
                            offset: new AMap.Pixel(0,0),
                            map: that.map,
                            id:that.dataList[i].estateId
                        })
                            
                        marker.on("click",function(e){//给每一个点位增加点击事件
                            console.log(marker.De.id)
                            // console.log(that.dataList[i].estateName)
                            that.showHouseList(marker.De.id,marker.De.title)
                            }); 
                        
                        markers.push(marker);
                    }
                     
                    
                }
                // window.eventHub.$emit('refreshList', '')// 更新右侧列表状态
            }, 1000)

            
            
            
        } catch(e) {
            console.log('资源获取失败')
        }
        
        },
        // 按条件查询房子
        foundHome(){
            if(this.nowType == 'ZF'){
                if(this.value2  == ''){
                    this.roomPriceMin='',
                    this.roomPriceMax=''
                }else if(this.value2  == 1){
                    this.roomPriceMin='0',
                    this.roomPriceMax='1000'
                }else if(this.value2  == 2){
                    this.roomPriceMin='1000',
                    this.roomPriceMax='2000'
                }else if(this.value2  == 3){
                    this.roomPriceMin='2000',
                    this.roomPriceMax='3000'
                }else if(this.value2  == 4){
                    this.roomPriceMin='3000',
                    this.roomPriceMax='4000'
                }else if(this.value2  == 5){
                    this.roomPriceMin='4000',
                    this.roomPriceMax='10000000'
                }
            }
            if(this.nowType == 'SP'){
                if(this.value4  == ''){
                    this.roomPriceMin='',
                    this.roomPriceMax=''
                }else if(this.value4  == 1){
                    this.roomPriceMin='0',
                    this.roomPriceMax='2000'
                }else if(this.value4  == 2){
                    this.roomPriceMin='2000',
                    this.roomPriceMax='5000'
                }else if(this.value4  == 3){
                    this.roomPriceMin='5000',
                    this.roomPriceMax='10000'
                }else if(this.value4  == 4){
                    this.roomPriceMin='10000',
                    this.roomPriceMax='20000'
                }else if(this.value4  == 5){
                    this.roomPriceMin='20000',
                    this.roomPriceMax='50000'
                }else if(this.value4  == 6){
                    this.roomPriceMin='50000',
                    this.roomPriceMax='100000'
                }else if(this.value4  == 7){
                    this.roomPriceMin='100000',
                    this.roomPriceMax='10000000'
                }
            }
            if(this.value5  == ''){
                    this.minArea='',
                    this.maxArea=''
                }else if(this.value5  == 1){
                    this.minArea='0',
                    this.maxArea='20'
                }else if(this.value5  == 2){
                    this.minArea='20',
                    this.maxArea='50'
                }else if(this.value5  == 3){
                    this.minArea='50',
                    this.maxArea='100'
                }else if(this.value5  == 4){
                    this.minArea='100',
                    this.maxArea='300'
                }else if(this.value5  == 5){
                    this.minArea='300',
                    this.maxArea='500'
                }else if(this.value5  == 6){
                    this.minArea='500',
                    this.maxArea='100000'
                }
            
            let params = {
                    type:this.nowType,
                    minPrice: this.roomPriceMin * 100, 
                    maxPrice: this.roomPriceMax * 100,
                    livingRoom: this.value1,
                    towards: this.value,
                    minArea:this.minArea,
                    maxArea:this.maxArea,
                    estateName : this.seachName,
                    }
                    request.post('/operating-real-estate/home/mapRoomSearch',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                            this.dataList = res.data
                             console.log(this.dataList.length)
                             if(this.dataList.length > 0){
                                this.draw(this.dataList[0].cdeAddressX,this.dataList[0].cdeAddressY)
                             }else {
                                this.draw()
                             }
                            }
                        } 
                    );
        },
        showHouseList(id){
            this.buildId = id
            let type = ''
            if(this.isActive  == 0){
                type = 'ZF'
            }else if (this.isActive == 1){
                type = 'SP'
            }else {
                type = 'BG'
            }
            this.showList = []
            this.dialogTableVisible = true
                let params = {
                    estateId:id,
                    livingRoom:type
                    }
                    request.post('/operating-real-estate/home/roomSearch',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.cont = res.data.totalCount
                                this.pageNum1 = res.data.totalPage
                                this.showList = res.data.list
                                
                            }
                        } 
                    );
        },
        // getHouseList(X,Y){
        //     console.log(X,Y)
        // },
        // async getData(){
        //     const res = await this.$axios.get('/index/v1.index/find');
        //     this.dataList = res.data;
        // },
        getCoordinateInformation(val){
            console.log(9527)
                let params = {
                    spare:'',
                    type:val   
                    }
                    request.post('/operating-real-estate/home/mapRoomSearch',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.dataList = res.data
                             console.log(this.dataList.length)
                             this.draw()
                            }
                        } 
                    );
            }, 
        createCoreScript() {
            return new Promise((resolve, reject) => {
                console.log('进入加载核心资源')
                let url = `https://webapi.amap.com/maps?v=1.4.2&key=${this.KEY}`;
                let $script = document.createElement('script');
                $script.id = 'coreScript';
                $script.src = url;
                $script.type = 'text/javascript';
                
                $script.addEventListener('load', (event) => {
                    console.log('核心script 获取成功')
                    resolve()
                })
                $script.onerror = function (e) {
                    console.log('js资源加载失败了')
                }
                document.body.appendChild($script)
            })
        },

        createMarker() {
            return new Promise((resolve, reject) => {
            let url = `https://webapi.amap.com/js/marker.js`;
            let $script = document.createElement('script');
            $script.id = 'markerScript';
            $script.src = url;
            $script.type = 'text/javascript';
            $script.addEventListener('load', (event) => {
                console.log('Marker script 获取成功')
                resolve()
            })
            document.body.appendChild($script)
            })
        },

        createDemo() {
            return new Promise((resolve, reject) => {
            let url = `https://webapi.amap.com/demos/js/liteToolbar.js`;
            let $script = document.createElement('script');
            $script.id = 'demoScript';
            $script.src = url;
            $script.type = 'text/javascript';
            $script.addEventListener('load', (event) => {
                console.log('demo script 获取成功')
                resolve()
            })
            document.body.appendChild($script)
            })
        },
        fromLowToHigh(){
           this.sort = !this.sort
           let type = ''
           if(this.isActive  == 0){
                type = 'ZF'
            }else if (this.isActive == 1){
                type = 'SP'
            }else {
                type = 'BG'
            }
           let params = {
                "livingRoom":type,
                "estateId":this.buildId,
                 "priceOrderBy" : "ASC",
            }
                    request.post('/operating-real-estate/home/roomSearch',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                              this.cont = res.data.totalCount
                                this.showList = res.data.list
                                console.log(this.showList)
                            }
                        }
                    );
       },
       fromHeightToLow(){
           this.sort = !this.sort
           let type = ''
           if(this.isActive  == 0){
                type = 'ZF'
            }else if (this.isActive == 1){
                type = 'SP'
            }else {
                type = 'BG'
            }
           let params = {
                "livingRoom":type,
                "estateId":this.buildId,
                 "priceOrderBy" : "DESC",
            }
                    request.post('/operating-real-estate/home/roomSearch',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                              this.cont = res.data.totalCount
                                this.showList = res.data.list
                                console.log(this.showList)
                            }
                        }
                    );
       },
       newHouse(){
           let params = {
                 "newOrderBy" : "",
            }
                    request.post('/operating-real-estate/home/roomSearch',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                              this.cont = res.data.estateNameList.totalCount
                                this.showList = res.data.estateNameList.list
                                console.log(this.showList)
                            }
                        }
                    );
       },
       seachMapHouse(){
           console.log("id",)
           let params = {
                "estateId" : this.buildId,
                 "estateName" : this.seachName,
                 "spare" : "1",
                  "pageNum" : this.pageNum || 1,
                 "pageRow" : this.pageRow || 10,
            }
                    request.post('/operating-real-estate/home/roomSearch',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                              this.cont = res.data.totalCount
                               if(this.showList.length > 0){
                                    this.showList = this.showList.concat(res.data.list)
                                }else{
                                    this.showList = res.data.list
                                }
                            }
                        }
                    );
       },
       handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageRow = val;
            this.seachMapHouse();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.seachMapHouse();
        },
        scrollEvent(e) {
            // console.log(e)
            if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight - 1 ) {
                console.log("嘿嘿我在底部触发了") 
                console.log(this.pageNum)
                console.log(this.pageNum1)
                if(this.pageNum < this.pageNum1){
                    this.pageNum ++
                    this.seachMapHouse();
                    
                }
                console.log(this.pageNum)
                
            }
        },
        checkedItem(item,index){
        this.seachName = ''
        this.isActive=index;
        this.divShow = index;
        this.nowType = item.type
        this.getCoordinateInformation(item.type)
    },

    },
    components:{
    Header,
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.serial-number{
    width: 50px;
    text-align: center;
    flex: 1;
}
.show-text{
    flex: 5;
    margin: 0px;
    padding: 0px;
}
.show-name{
    // width: 135px;
    height: 16px;
    font-size: 16px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #333333;
    line-height: 16px;
    text-align: left;
    margin-bottom: 20px;
}
.show-index{
    // width: 138px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    text-align: left;
    margin-bottom: 10px;
}
.show-price{
    text-align: left;
}
.map-price{
    display: inline-block;
    height: 20px;
    font-size: 20px;
    font-family: DINAlternate-Bold, DINAlternate;
    font-weight: bold;
    color: #D93535;
    line-height: 20px;
    }
    .map-company{
        display: inline-block;
        width: 30px;
        height: 12px;
        font-size: 12px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #D93535;
        line-height: 12px;
    }
.show-ul{
    text-align: left;
}
.map-text-end-ul > li{
        display: inline-block;
        height: 18px;
        background: #E8F3FF;
        border-radius: 4px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #3094FF;
        line-height: 18px;
        margin-right: 5px;
        text-align: center;
    }
.one{
    width: 24px;
    height: 24px;
    background: #D93535;
    border-radius: 50%;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 24px;
}
.two{
    width: 24px;
    height: 24px;
    background: #F0942D;
    border-radius: 50%;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 24px;
}
.three{
    width: 24px;
    height: 24px;
    background: #EACC26;
    border-radius: 50%;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 24px;
}
.other{
    width: 24px;
    height: 24px;
    background: #E4E9EE;
    border-radius: 50%;
    font-size: 14px;
    font-family: PingFang-SC-Semibold, PingFang-SC;
    font-weight: 600;
    color: #333333;
    line-height: 24px;
}
.ceshi{
        color: blue;
        display: inline-block;
        margin-right: 50px;
        // width: 72px;
        height: 18px;
        font-size: 18px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        cursor:pointer;
    }
.active{
        // width: 72px;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333; 
        border-bottom: 4px solid #000000; 
        cursor:pointer;
        // background-repeat: no-repeat;
        // background-image: linear-gradient(90deg, #000 100%, #f00 100%);
        // background-size: 50% 3px;
        // background-position: 50% 100%;
        // display: inline-block;
    }
.houseSearch-btn{
        display: inline-block;
        width: 100px;
        height: 60px;
        line-height: 60px;
        background: #F2F3F8;
        text-align: center;
        border: 1px solid #121111;
        border-left: none;
        border-radius: 0px 8px 8px 0px;
        margin-top:30px
}

	.inside-top-left > input{
        width: 645px;
        height: 60px;
        background: #F2F3F8;
        padding: 0px;
        font-size: 16px;
        border-radius: 8px 0px 0px 8px;
        border: 1px solid #121111;
        border-right: none;
        outline:none;
        padding: 0px 0px 0px 25px;
        vertical-align: top;
        margin-top:30px
}
.condition-outside-box{
    width: 1920px;
    height: 162px;
    background: #FFFFFF;
    .condition-inside-box{
        height: 100%;
        width: 1160px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .inside-top{
            flex: 1;
            height: 100%;
            display: flex;
            flex-direction: row;
            .inside-top-left{
                flex: 8;
                text-align: left; 
            }
            .inside-top-right{
                flex: 2;
                cursor: pointer;
                .btn-back{
                    float: right;
                    width: 160px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 8px;
                    margin-top: 30px;
                    border: 1px solid #3094FF; 
                    img{
                        margin-right: 5px;
                        vertical-align: middle;
                    }
                    span{
                        font-size: 16px;
                        font-family: PingFang-SC-Medium, PingFang-SC;
                        font-weight: 500;
                        color: #333333;
                        vertical-align: middle;
                    }
                }
            }
        }
        .inside-buttom{
            flex: 1;
            height: 100%;
            text-align: left;
            .inside-buttom-first{
                display: inline-block;
                margin-top: 17px;
            }
            .inside-buttom-second{
                display: inline-block;
                .selection-box{
                    margin-left: 10px;
                    display: inline-block;
                }
            }
        }
    }
}
.container1{
    width: 100%;
    height: calc(100vh - 232px);
    // height: 77vh;
    margin: 0 auto;
    #container{
        height: 100%;
        margin: 0px;
        font:12px Arial;
    }
    .taiwan{
        border: solid 1px rgb(223, 52, 52);
        color: red;
        float: left;
        width: 50px;
        background-color: rgba(228, 162, 40, 0.1);
        background-color: #fff;
    }
}









/deep/.el-dialog{
    margin: 0px;
    height: 680px;
    pointer-events: auto;
}
/deep/.el-dialog__header{
    padding: 0px;
}
.el-dialog__wrapper{
    pointer-events: none;
}









.map-show-ul{
        margin-bottom: 30px;
        height:510px;
        overflow-y: auto;
        
    }
    .map-show-ul > li{
        margin-bottom: 10px;
        
    }
    .link{
        width: 100%;
        height: 120px;
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;
    }
    .map-show-nav-ul > li{
        display: inline-block;
        width: 110px;
        height: 18px;
        font-size: 18px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-right: 20px;
        cursor:pointer;
    }
    .map-show-nav-li-end > img{
        display: inline-block;
    }
    
    
   
    
    
    
    .map-show-text{
        width: 385px;
        height: 30px;
        font-size: 30px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        margin-bottom: 20px;
        text-align: left;
    }
    .map-show-text > span{
        color: #3094FF ;
    }

</style>
