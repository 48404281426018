// import { message } from 'antd';
import axios from 'axios';
// import isEmpty from 'is-empty';
// import { getLanguage } from './tools';

// const BASE_URL = 'https://api.lagzxl.com/qsh-hotel';
// const BASE_URL = 'http://192.168.1.242:1717/o-r-e-u/'; //斌哥
//  const BASE_URL = './o-r-e-u/'; //线上
const BASE_URL = 'https://api.lagzxl.com/o-r-e-u/';
const TIME_OUT = 30 * 1000;
const METHOD_GET = 'get';
const METHOD_PUT = 'put';
const METHOD_POST = 'post';
// const KEY_TOKEN = 'token';

export default new class Request {
	constructor() {
		axios.defaults.baseURL = BASE_URL;
		axios.defaults.timeout = TIME_OUT;
		// axios.interceptors.request.use(config => {
		// 	if (this.hasToken()) {
		// 		config.headers.authorization = `Bearer ${this.getToken()}`;
		// 	}
		// 	return config;
		// });
		axios.interceptors.response.use(config => {
			return config.data;
		});
	}
//get  header  post  header body
	request = (url, params, config) => {
		return new Promise((resolve, reject) => {
			axios({
				method: config.method || 'GET',
				url: url,
				data: params || {},
                // headers:{},
				headers: config.headers || {
					'Content-Type': 'application/json',
					'Access-Control-Allow-Origin': '*',
				},
				// headers: config.headers || {
				// 	'Content-Type': 'application/json',
				// 	'Access-Control-Allow-Origin': '*' 
				// },
				
				params:    config.method == METHOD_GET ? params : {}
			}).then(res => {
				if(res.result===3011){
					localStorage.setItem(KEY_TOKEN, "");
				}

				// get请求失败统一提示
				if (config.method == METHOD_GET) {
					if (res.code != 200) {
						// message.info(res.message)
					}
				}

				resolve(res);
			}).catch(err => {
				reject(err);
			});
		});
	};

	getToken = () => {
        // return localStorage.getItem(KEY_TOKEN);
        return '6f5915ad4dcc474cb106c468d30bd30a'
		// return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dpblR5cGUiOiJsb2dpbiIsImxvZ2luSWQiOjE0ODYyNDQ0MDg2MzQ0Nzg2MDAsImRldmljZSI6ImRlZmF1bHQtZGV2aWNlIiwiZWZmIjotMX0.eTMU5ZxaFRRI0eUEYh8YEL15bxsc8RpUGrHu4JqfvhA'
	};

	setToken= (newToken) => {
		localStorage.setItem(KEY_TOKEN, newToken);
	}

	hasToken = () => {
		const token = this.getToken();
		return token;
        //  && !isEmpty(token);
	};

	get = (url, params) => {
		return this.request(url, params, { method: METHOD_GET });
	};

	put = (url, params) => {
		return this.request(url, params, { method: METHOD_PUT });
	};

	post = (url, params) => {
		return this.request(url, params, { method: METHOD_POST });
	};

	upload = (url, params) => {
		return this.request(url, params, {
			method: METHOD_POST,
			headers: { 'Access-Control-Allow-Origin': '*', 'content-type': 'multipart/form-data' }
		});
	};
}();









