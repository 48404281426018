<template>
<div>
    
    <div style="width:100%;min-width:1520px; height:70px;background:#292929 ;">
        <Header :isActive="2"></Header>
    </div>
    <div style="width:100%;min-width: 1500px;">
       <div class="chiose">
           <div class="box">
               <div>
                   <input v-model="searchContent" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" class="chiose-input" type="text" placeholder="请输入街道或大楼名称" >
                    <div class="btn">
                        <img @click="searchListings" src="../../assets/rentHouseSearch/search.png" alt="">
                    </div>
                    <div class="map">
                        <img src="../../assets/rentHouseSearch/map.png" alt="">
                        <span @click="$router.push('/map')">地图找房</span>
                    </div>
               </div>
               <div class="condition" style="height:32px">
                   <span style="margin-top: 12px;">类型</span>
                    <ul style="vertical-align:top" class="condition-ul">
                        <li ><el-button  class="li-btn" style="color: #409EFF;" type="text">住宅</el-button></li>
                        <li><el-button @click="$router.push('/leaseShopSearch')" class="li-btn" type="text">商铺</el-button></li>
                        <li><el-button class="li-btn" @click.prevent="$router.push('/workRoomSearch')" type="text">办公用房</el-button></li>
                    </ul>
               </div>
               <div class="condition" style="height:60px">
                   <span style="margin-top: 12px;">区域</span>
                    <ul class="condition-ul">
                        <li  v-for="item in streetList"><el-button @click="informationChange(item.name)" :class="{'li-btn': true,'hostIndex':isActiveIndex == item.name }" type="text">{{item.name}}</el-button></li>
                        <!-- <li><el-button class="li-btn" type="text">文字按钮</el-button></li>
                        <li><el-button class="li-btn" type="text">文字按钮</el-button></li> -->
                    </ul>
               </div>
               <div class="condition">
                   <span style="margin-top: 9px;">租金</span>
                    <el-radio-group v-model="radio1" @change="informationChange()" style="display:inline-block">
                        <el-radio label="">不限</el-radio>
                        <el-radio label="1">&lt;1000元</el-radio>
                        <el-radio label="2">1000-2000元</el-radio>
                        <el-radio label="3">2000-3000元</el-radio>
                        <el-radio label="4">3000-4000元</el-radio>
                        <el-radio label="5">&gt;4000元</el-radio>
                    </el-radio-group>
                    <div style="display:inline-block;text-align: center;margin-left: 30px;">
                        <input class="price-inpt" v-model="roomPriceMin" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" @change="getTotalChange(1)" type="text"> - 
                        <input class="price-inpt" v-model="roomPriceMax" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" @change="getTotalChange(2)" type="text">
                        <el-button style="margin-left:30px" @click="queryPrice" type="text">确认</el-button>
                    </div>
               </div>
               <div class="condition">
                   <span style="margin-top: 7px;">户型</span>
                   <el-radio-group v-model="radio2" @change="informationChange()" style="display:inline-block">
                        <el-radio label="">不限</el-radio>
                        <el-radio label="1">一居室</el-radio>
                        <el-radio label="2">二居室</el-radio>
                        <el-radio label="3">三居室</el-radio>
                        <el-radio label="4">四居室以上</el-radio>
                    </el-radio-group>
               </div>
               <div class="condition">
                   <span style="margin-top: 6px;">朝向</span>
                   <el-radio-group v-model="radio3" @change="informationChange()" style="display:inline-block">
                        <el-radio label="">不限</el-radio>
                        <el-radio label="SOUTH">朝南</el-radio>
                        <el-radio label="WEST">朝西</el-radio>
                        <el-radio label="EAST">朝东</el-radio>
                        <el-radio label="NORTH">朝北</el-radio>
                    </el-radio-group>
               </div>
               <!-- <div class="condition">
                   <span style="margin-top: 7px;">装修</span>
                   <el-radio-group v-model="radio4" @change="informationChange()" style="display:inline-block">
                        <el-radio label="">不限</el-radio>
                        <el-radio label="HH">豪华</el-radio>
                        <el-radio label="JZ">精装修</el-radio>
                        <el-radio label="JD">简装</el-radio>
                        <el-radio label="MP">毛坯</el-radio>
                    </el-radio-group>
               </div> -->
               
           </div>
       </div>
       <div class="show">
           <div class="box show-box">
               <div style="flex:8.5">
                   <div class="show-text">已为您找到<span>{{ totalCount }}</span>套精选住宅</div>
                    <div style="margin-bottom:32px">
                        <ul class="show-nav-ul">
                            <!-- <li>综合排序</li>
                            <li @click="newArrival">最新上架</li> -->
                            <li class="show-nav-li-end">
                                价格排序
                                <img @click="fromLowToHigh" v-if="sort" src="../../assets/rentHouseSearch/lower.png" alt="">
                                <img @click="fromHeightToLow" v-else src="../../assets/rentHouseSearch/up.png" alt="">
                            </li>
                        </ul>
                    </div>
                    <!-- <router-link class="link" to="/rentHouseDetails"> -->
                        <div>
                            <ul class="show-ul">
                                <li style="margin-bottom:20px" v-for="item in houseList" >
                                    <!-- <router-link class="link" :to="{path:'/rentShopsDetails',query: { roomId: item.roomId}}"> -->
                                    <router-link class="link" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                        <div class="house-box">
                                            <img :src="item.roomPic" alt="">
                                            <div class="house-text">
                                                <div class="text-first">{{item.estateName}} / {{item.roomName}}</div>
                                                <div class="text-second">
                                                    
                                                    <div style="display: flex;justify-content: space-between;align-items: center;">
                                                        <div class="text-second-first" ><img src="../../assets/rentHouseSearch/little-house.png" alt="">{{item.area}} / {{item.roomType}} / {{item.turnType}}</div>
                                                        <!-- <div style="margin-left:10px" class="company"></div> -->
                                                        <div class="price"> <i style="font-size: 25px;font-style: normal;font-weight: bold;">{{item.roomPrice}}</i> 元/月</div>
                                                    </div>
                                                    
                                                    
                                                </div>
                                                <div class="text-end">
                                                    <ul class="text-end-ul">
                                                        <!-- <li v-for="i in item.trait">{{i}}</li>  -->
                                                        <li>{{item.trait[0]}}</li>
                                                        <li>{{item.trait[1]}}</li>
                                                        <li>{{item.trait[2]}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    
                                </li>
                            </ul>
                        </div>
                    <!-- </router-link> -->
               </div>
                <div class="floating-frame">
                    <div>欢迎使用<br>临安国资小程序</div>
                    <img class="qrCode" src="../../assets/home/qrCode.png" alt="">
                </div>
           </div>
       </div>
       
       <div style="height: 60px;background: #F2F3F8;">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="1"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
            </el-pagination>
        </div>


    </div>   
</div>
    
    
</template>
<script>
import Header from '../../components/header.vue'
import request from '../../api/index'
export default {
    name:'RentHouseSearch',
    data(){
        return{
            radio1: '',
            radio2: '',
            radio3: '',
            radio4: '',
            searchContent:'',
            roomType:'',
            houseList:[],
            sort:true,
            streetList:[
            {name:'不限'},
            {name:'锦城街道'},
            {name:'锦北街道'},
            {name:'锦南街道'},
            {name:'青山湖街道'},
            {name:'玲珑街道'},
            {name:'高虹镇'},
            {name:'板桥镇'},
            {name:'太湖源镇'},
            {name:'天目山镇'},
            {name:'於潜镇'},
            {name:'太阳镇'},
            {name:'昌化镇'},
            {name:'河桥镇'},
            {name:'湍口镇'},
            {name:'清凉峰镇'},
            {name:'龙岗镇'},
            {name:'岛石镇'},
            {name:'潜川镇'},

            ],
            isActiveIndex:'不限',
            roomPriceMin:'',
            roomPriceMax:'',
            location:'',
            // 分页信息
            // 总条数
            totalCount:null,
            // 页数
            pageNum:null,
            // 每页多少条
            pageRow:null,

        }
    },
    mounted() {
        this.searchContent = this.$route.params.searchContent;
        this.roomType = 'ZF';
        this.searchListings()
    },
    methods:{
        // 查询价格
        queryPrice(){
           let params = {
                    'roomName':this.searchContent,
                    "roomType" : "ZF",
                    "location" : this.location,
                    "roomPriceMin" : this.roomPriceMin * 100,
                    "roomPriceMax" : this.roomPriceMax * 100,
                    "houseType" : this.radio2,
                    "furnish" : this.radio4,
                    "towards" : this.radio3,
                // "roomPriceMin" : this.roomPriceMin *100,
                // "roomPriceMax" : this.roomPriceMax *100,
            }
                    request.post('/operating-real-estate/home/searchRoom',params)
                    .then(
                        res=>{
                            console.log(res.result)
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                             this.totalCount = res.data.totalCount;
                            }
                        }
                    ); 
        },
        // 查询区域
        // queryArea(val){
        //     if(val == '不限'){
        //         val = ''
        //     }
        //     this.location = val;

        //     let params = {
        //          "location" : val,
        //     }
        //             request.post('/operating-real-estate/home/searchRoom',params)
        //             .then(
        //                 res=>{
        //                     console.log(res.result)
        //                     if (res.result == 0) {
        //                      this.houseList = res.data.list;
        //                       this.totalCount = res.data.totalCount;
        //                     }
        //                 }
        //             );
        // },
        // 信息改变查询
        informationChange(val){
          
            if(val){
                this.isActiveIndex = val
                if(val == '不限'){
                    this.location = ''
                }else{
                    this.location = val
                }
            }
            if(this.radio1  == ''){
                this.roomPriceMin='',
                this.roomPriceMax=''
            }else if(this.radio1  == 1){
                this.roomPriceMin='0',
                this.roomPriceMax='1000'
            }else if(this.radio1  == 2){
                this.roomPriceMin='1000',
                this.roomPriceMax='2000'
            }else if(this.radio1  == 3){
                this.roomPriceMin='2000',
                this.roomPriceMax='3000'
            }else if(this.radio1  == 4){
                this.roomPriceMin='3000',
                this.roomPriceMax='4000'
            }else if(this.radio1  == 5){
                this.roomPriceMin='4000',
                this.roomPriceMax='10000000'
            }
            let params = {
                    'roomName':this.searchContent,
                    "roomType" : "ZF",
                    "location" : this.location,
                    "roomPriceMin" : this.roomPriceMin * 100,
                    "roomPriceMax" : this.roomPriceMax * 100,
                    "houseType" : this.radio2,
                    "furnish" : this.radio4,
                    "towards" : this.radio3,
            }
                    request.post('/operating-real-estate/home/searchRoom',params)
                    .then(
                        res=>{
                            console.log(res.result)
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                            }
                        }
                    );
        },

        searchListings(){
          let params = {
                "location" : this.location,
                "roomPriceMin" : this.roomPriceMin * 100,
                "roomPriceMax" : this.roomPriceMax * 100,
                "houseType" : this.radio2,
                "furnish" : this.radio4,
                "towards" : this.radio3,
                 "roomName" : this.searchContent,
                 "roomType" : this.roomType,
                 "pageNum" : this.pageNum || 1,
                 "pageRow" : this.pageRow || 10,
            }
                    request.post('/operating-real-estate/home/searchRoom',params)
                    .then(
                        res=>{
                            console.log(res.result)
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                              
                            }
                        }
                    );
       }, 
    //    最新上架
       newArrival(){
           let params = {
                "location" : this.location,
                "roomPriceMin" : this.roomPriceMin * 100,
                "roomPriceMax" : this.roomPriceMax * 100,
                "houseType" : this.radio2,
                "furnish" : this.radio4,
                "towards" : this.radio3,
                "roomName" : this.searchContent,
                "newTime" : "DESC",
                "roomType" : "ZF",
                "pageNum" : this.pageNum || 1,
                "pageRow" : this.pageRow || 10,
           }
           request.post('/operating-real-estate/home/searchRoom',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                            }
                        }
                    );
       },
    //    价格排序
       fromLowToHigh(){
           this.sort = !this.sort
           let params = {
                "location" : this.location,
                "roomPriceMin" : this.roomPriceMin * 100,
                "roomPriceMax" : this.roomPriceMax * 100,
                "houseType" : this.radio2,
                "furnish" : this.radio4,
                "towards" : this.radio3,
                "roomName" : this.searchContent,
                 "orderBy" : "ASC",
                 "roomType" : "ZF",
                 "pageNum" : this.pageNum || 1,
                 "pageRow" : this.pageRow || 10,
            }
                    request.post('/operating-real-estate/home/searchRoom',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                            }
                        }
                    );
       },
       fromHeightToLow(){
           this.sort = !this.sort
           let params = {
                "location" : this.location,
                "roomPriceMin" : this.roomPriceMin * 100,
                "roomPriceMax" : this.roomPriceMax * 100,
                "houseType" : this.radio2,
                "furnish" : this.radio4,
                "towards" : this.radio3,
                "roomName" : this.searchContent,
                 "orderBy" : "DESC",
                 "roomType" : "ZF",
                 "pageNum" : this.pageNum || 1,
                 "pageRow" : this.pageRow || 10,
            }
                    request.post('/operating-real-estate/home/searchRoom',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                            }
                        }
                    );
       },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageRow = val;
            this.searchListings();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.searchListings();
        },
        getTotalChange(type){
            if(type == 1){
                if (this.roomPriceMin < 0) {
                    this.$message({
                        type: 'warning',
                        message: '租金最小值不能小于0'
                    })
                    this.roomPriceMin = 0
                }
            }else{
                if (this.roomPriceMax < 0 || this.roomPriceMax < this.roomPriceMin) {
                    this.$message({
                        type: 'warning',
                        message: '租金最大值不能小于0，且不能小于最小值'
                    })
                    this.roomPriceMax = 0
                }
            }
            
            
        }
    },
    components:{
    Header,
    }
}
</script>

<style lang="less" scoped>
    .hostIndex{
        color: #3094FF !important;
    }
    .company{
        display: inline-block;
        height: 20px;
        font-size: 12px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #E76565;
        line-height: 20px;
        float: right;
        margin-right: 10px;
    }
    .chiose{
        width: 100%;
        height: 450px;
    }
    .box{
        width: 1070px;
        height: 100%;
        margin: 0 auto;
        text-align: left;
    }
    .chiose-input{
        width: 645px;
        height: 60px;
        background: #F2F3F8;
        padding: 0px;
        font-size: 16px;
        border-radius: 8px 0px 0px 8px;
        // border: 1px solid #121111;
        border: none;
        outline:none;
        padding: 0px 0px 0px 25px;
        vertical-align: top;
        margin-top:30px;
    }
    .btn{
        display: inline-block;
        width: 100px;
        height: 60px;
        line-height: 60px;
        background: #F2F3F8;
        text-align: center;
        // border: 1px solid #121111;
        border: none;
        border-radius: 0px 8px 8px 0px;
        margin-top:30px
    }
    .btn > img{
        vertical-align: middle;
    }
    .map{
        text-align: center;
        display: inline-block;
        width: 160px;
        height: 54px;
        line-height: 54px;
        cursor:pointer;
        border-radius: 8px;
        border: 1px solid #3094FF;
        margin-left: 135px;
        margin-top:30px
    }
    .map > img{
        vertical-align: middle;
        margin:0px 5px 0px 0px;
    }
    .map > span{
        width: 64px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
    }
    .condition{
        height: 32px;
        line-height: 32px;
        margin: 30px 0;
    }
    .condition-ul{
        display: inline-block;
        width: 1000px;
    }
    .condition-ul > li{
        display: inline-block;
        margin-right: 30px;
    }
    .li-btn{
        width: 64px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
    }
    .price-inpt{
        display: inline-block;
        width: 60px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #B4B9C8;
        margin: 0;
        padding: 0;
    }
    .condition > span{
        display: inline-block;
        margin-right: 30px;
        width: 32px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 16px;
        vertical-align: top;
    }
    .show{
        width: 100%;
        // height: 500px;
        background: #F2F3F8;
        display: flex;
        flex-direction: row;
    }
    .show-box{
        display: flex;
        flex-direction: row;
    }
    .floating-frame{
        text-align: left;
        flex: 1.5;
        margin-top: 44px;
    }
    .floating-frame > div{
        width: 112px;
        height: 46px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
    }
    .show-text{
        // width: 385px;
        height: 30px;
        font-size: 30px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        padding: 40px 0px 0px 0px;
        margin-bottom: 40px;
    }
    .show-text > span{
        color: #3094FF ;
    }
    .show-ul{
        margin-bottom: 30px;
    }
    .show-nav-ul > li{
        display: inline-block;
        width: 110px;
        height: 18px;
        font-size: 18px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-right: 50px;
        cursor:pointer;
    }
    .show-nav-li-end > img{
        display: inline-block;
    }
    .house-box > img{
        vertical-align: middle;
        width: 280px;
        height: 180px;
    }
    .house-text{
        display: inline-block;
        vertical-align: middle;
        width: 620px;
        height: 180px;
        background: #FFFFFF;
        border-radius: 4px;
    }
    .text-first{
        // width: 176px;
        height: 24px;
        font-size: 24px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        margin: 20px 0px 30px 20px;
        display: -webkit-box;/*作为弹性伸缩盒子模型显示*/
        -webkit-line-clamp: 2; /*显示的行数；如果要设置2行加...则设置为2*/
        overflow: hidden; /*超出的文本隐藏*/
        text-overflow: ellipsis; /* 溢出用省略号*/
        -webkit-box-orient: vertical;/*伸缩盒子的子元素排列：从上到下*/
    }
    .text-second{
        margin: 0px 0px 20px 20px;
    }
    .text-second > img{
        margin-right: 9px;
        vertical-align: middle;
    }
    .text-second-first{
        // width: 195px;
        height: 18px;
        font-size: 18px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        vertical-align: middle;
    }
    .text-end-ul > li{
        display: inline-block;
        margin-left: 20px;
        margin-top: 10px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #3094FF;
        line-height: 30px;
        // width: 70px;
        padding: 0px 5px;
        height: 30px;
        background: #E8F3FF;
        border-radius: 4px;
        text-align: center;
    }
    .price {
        color: #E76565;
        text-align: right;
        font-weight: 500;
        margin-right: 10px;
    }

</style>
