<template>
    <div>
        <div style="width:100%;min-width: 1500px; height:70px;background:#292929 ;">
            <Header :isActive="2"></Header>
        </div>
        <div style="width:100%;min-width: 1500px;">
            <div class="choose">
                <div class="box">
                    <div>
                        <input class="chiose-input" v-model="searchContent" type="text">
                            <div class="btn">
                                <img @click="$router.push({name:'RentHouseSearch',params:{searchContent:searchContent}})" src="../../assets/rentHouseSearch/search.png" alt="">
                            </div>
                            <div class="map">
                                <img src="../../assets/rentHouseSearch/map.png" alt="">
                                <span @click="$router.push('/map')">地图找房</span>
                            </div>
                    </div>
                    <div style="display:flex;flex-direction: row;">
                        <div class="house-data">
                            <div class="house-data-first">{{roomName}} {{roomType}} {{turnType}}</div>
                            <div class="house-data-second">租房 / {{communityName}} / {{estateName}} / 当前房源</div>
                        </div>
                        <div class="follow-data">
                            <img @click="cancelCollection" v-if="collect" src="../../assets/rentHouseDetails/follow.png" alt="">
                            <img @click="collection" v-else src="../../assets/rentHouseDetails/not-follow.png" alt="">
                            <div>关注房源</div>
                        </div>  
                    </div>  
                </div>
            </div>
            <div class="detailed-data">
                <div class="detailed-data-box">
                    <div class="detailed-data-box-left">
                      <div class="swiper_box">
                        <div class="thumb-example">
                          <!-- swiper1 -->
                          <swiper class=" gallery-top" :options="swiperOptionTop" ref="swiperTop">
                            <swiper-slide class="slide-1" v-for="(item,index) in list_img" :key="index">
                              <img :src="item" style="height:570px;width:100%" alt="" />
                            </swiper-slide>
                            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
                            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
                          </swiper>
                          <!-- swiper2 Thumbs -->
                          <swiper class="gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                            <swiper-slide class="slide" style="width:100px;height:100px;" v-for="(item,index) in list_img" :key="index">
                              <img style="width:100px;height:100px;" :src="item" alt="" />
                            </swiper-slide>
                          </swiper>
                        </div>
                      </div>
<!--                            <el-carousel height="500px" indicator-position="outside" :autoplay="false" :loop="false">-->
<!--                                <el-carousel-item v-for="(item) in list_img">-->
<!--                                    <h3 class="small">-->
<!--                                        <img :src="item" alt />-->
<!--                                    </h3>-->
<!--                                    <el-image-->
<!--                                        style="width: 190px; height: 300px;"-->
<!--                                        :src="item"-->
<!--                                        :fit="fit"-->
<!--                                        :preview-src-list="list_img"-->
<!--                                    >-->
<!--                                    </el-image>-->
<!--                                </el-carousel-item>-->
<!--                            </el-carousel>-->
                        <div>
                             <el-tabs v-model="activeName" >
                                <el-tab-pane name="first">
                                    <div style="color:black" slot="label" @click="onToYear1">房屋信息</div>
                                </el-tab-pane>
                                <el-tab-pane name="second">
                                    <div style="color:black" slot="label" @click="onToYear2">房屋图片</div>
                                </el-tab-pane>
                                <el-tab-pane name="third">
                                    <div style="color:black" slot="label" @click="onToYear3">周边位置</div>
                                </el-tab-pane>
                                <el-tab-pane name="fourth">
                                    <div style="color:black" slot="label" @click="onToYear4">附近房源</div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        <div class="house-details-data">
                            <div id="year1" class="house-details-data-text">房屋信息</div>
                            <div class="house-details-data-box">
                                <div class="detailed-information">
                                    <div class="detailed-information-first">基本信息</div>
                                    <div style="flex:8;padding-top: 20px;">
                                         <div class="information-box">
                                             <span class="information-name">面积</span>
                                             <span class="information-content">{{area}}</span>
                                         </div>
                                         <div  class="information-box">
                                             <span class="information-name">朝向</span>
                                             <span class="information-content">{{turnType}}</span>
                                         </div>
                                         <div  class="information-box">
                                             <span class="information-name">楼层</span>
                                             <span class="information-content">7/14</span>
                                         </div>
                                         <div  class="information-box">
                                             <span class="information-name">电梯</span>
                                             <span class="information-content">有</span>
                                         </div>
                                         <div  class="information-box">
                                             <span class="information-name">用水</span>
                                             <!-- <span class="information-content">{{water}}</span> -->
                                             <span class="information-content">民用水</span>
                                         </div>
                                         <div class="information-box">
                                             <span class="information-name">用电</span>
                                             <!-- <span class="information-content">{{electricity}}</span> -->
                                             <span class="information-content">民用电</span>
                                         </div>
                                         <div style="margin-bottom:20px">
                                             <span class="information-name">装修</span>
                                             <span class="information-content">精装修</span>
                                         </div>
                                         <div style="margin-bottom:20px">
                                             <span class="information-name">房性特点</span>
                                             <ul style="display:inline-block" class="text-end-ul">
                                                <li  v-for="item in trait">{{item}}</li>
                                                <!-- <li>{{trait[0]}}</li>
                                                <li>{{trait[1]}}</li>
                                                <li>{{trait[2]}}</li> -->
                                            </ul>
                                         </div>
                                    </div>
                                </div>
                                <div class="detailed-information">
                                    <div class="detailed-information-first">配套设施</div>
                                    <div class="supporting-facilities">
                                        <ul class="supporting-facilities-ul">
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('1') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑空调.png" alt="">
                                                    <div class="not-existence">空调</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/空调.png" alt="">
                                                    <div class="existence">空调</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('2') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑冰箱.png" alt="">
                                                    <div class="not-existence">冰箱</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/冰箱.png" alt="">
                                                    <div class="existence">冰箱</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('3') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑电视.png" alt="">
                                                    <div class="not-existence">电视</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/电视.png" alt="">
                                                    <div class="existence">电视</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('4') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑洗衣机.png" alt="">
                                                    <div class="not-existence">洗衣机</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/洗衣机.png" alt="">
                                                    <div class="existence">洗衣机</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('5') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑热水器.png" alt="">
                                                    <div class="not-existence">热水器</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/热水器.png" alt="">
                                                    <div class="existence">热水器</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('6') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑衣柜.png" alt="">
                                                    <div class="not-existence">衣柜</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/衣柜.png" alt="">
                                                    <div class="existence">衣柜</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('7') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑床.png" alt="">
                                                    <div class="not-existence">床</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/床.png" alt="">
                                                    <div class="existence">床</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('8') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑微波炉.png" alt="">
                                                    <div class="not-existence">微波炉</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/微波炉.png" alt="">
                                                    <div class="existence">微波炉</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('9') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑桌椅.png" alt="">
                                                    <div class="not-existence">桌椅</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/桌椅.png" alt="">
                                                    <div class="existence">桌椅</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-if="facArry.indexOf('10') == -1">
                                                    <img src="../../assets/rentHouseDetails/黑电磁炉.png" alt="">
                                                    <div class="not-existence">电磁炉</div>
                                                </div>
                                                <div v-else>
                                                    <img src="../../assets/rentHouseDetails/电磁炉.png" alt="">
                                                    <div class="existence">电磁炉</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="detailed-information">
                                    <div class="detailed-information-first">详情描述</div>
                                    <div class="detailed-description">
                                        {{details}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="house-details-img">
                            <div id="year2" class="house-details-data-text">房屋图片</div>
                            <div>
                                <ul>
                                    <li v-for="item in list_img" class="house-details-img-li"><img :src="item" alt=""></li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div id="year3" class="house-details-data-text">周边位置</div>
                            <div id="container" style="width:100%;height:350px;"></div>
                        </div>
                        <div>
                            <div id="year4" class="house-details-data-text">附近房源</div>
                            <div class="nearby-houses">
                                <ul class="nearby-houses-ul">
                                    <li style="margin-right: 15px;" v-for="item in nearbyHousesList">
                                        <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                            <div><img class="nearby-houses-img" :src="item.showPictures" alt=""></div>
                                            <div class="nearby-houses-text-line">{{item.roomName}}</div>
                                            <div style="margin: 0 10px;">
                                                <div class="nearby-houses-describe">{{item.roomType}}</div>
                                                <template v-if = "item.roomPrice && item.roomPrice!='' ">
                                                    <div class="nearby-houses-company">元/月</div>
                                                    <div class="nearby-houses-price">{{item.roomPrice}}</div>
                                                </template>
                                                <div v-else class="box-right-one-second" style=" width: auto;float: right;">暂无价格信息</div>
                                            </div>
                                        </router-link>
                                        
                                    </li>
                                    <!-- <li>
                                        <div><img class="nearby-houses-img" src="../../assets/home/model.png" alt=""></div>
                                        <div class="nearby-houses-text">镇北街道  碧桂园</div>
                                        <div>
                                            <div class="nearby-houses-describe">2室2厅1卫</div>
                                            <div class="nearby-houses-price">6666</div>
                                            <div class="nearby-houses-company">元/月</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div><img class="nearby-houses-img" src="../../assets/home/model.png" alt=""></div>
                                        <div class="nearby-houses-text">镇北街道  碧桂园</div>
                                        <div>
                                            <div class="nearby-houses-describe">2室2厅1卫</div>
                                            <div class="nearby-houses-price">6666</div>
                                            <div class="nearby-houses-company">元/月</div>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div class="detailed-data-box-right">
                        <div class="data-box-right-first1">
                            <div class="box-right-one">
                                <template v-if = "roomPrice && roomPrice!='' ">
                                     <div class="box-right-one-first">{{roomPrice}}</div>
                                    <div class="box-right-one-second">元/月</div>
                                </template>
                                <div v-else class="box-right-one-second" style="margin: 10px 0 20px 20px;width: 100%;">当前房源暂无价格信息</div>
                            </div>
                            <div class="box-right-two">
                                <span>{{roomType}}</span>
                                <span>{{turnType}}</span>
                                <span>{{area}}</span>
                            </div>
                            <div class="box-right-three">
                                <span>{{floor}}</span>
                                <span>平层/精装</span>
                                <span>2015年建/板楼</span>
                            </div>
                            <div class="box-right-four">
                                <ul class="text-end-ul">
                                    <li v-for="item in trait">{{item}}</li>
                                    <!-- <li>{{trait[0]}}</li>
                                    <li>{{trait[1]}}</li>
                                    <li>{{trait[2]}}</li> -->
                                </ul>
                            </div>
                            <div class="box-right-five">
                                <div style="display: inline-block;vertical-align: top;" class="box-right-five-first">大楼名称</div>
                                <div style="display: inline-block;vertical-align: top;" class="box-right-five-second">{{estateName}}</div>
                            </div>
                            <div class="box-right-six">
                                <div style="display: inline-block;vertical-align: top;" class="box-right-five-first">所在区域</div>
                                <div style="display: inline-block;vertical-align: top;" class="box-right-five-second">{{communityName}}</div>
                            </div>
                            <div class="box-right-seven">
                                <div style="display: inline-block;vertical-align: top;" class="box-right-five-first">详细地址</div>
                                <div style="display: inline-block;vertical-align: top;" class="box-right-five-second">{{location}}</div>
                            </div>
                            <div class="box-right-eight">
                                <button class="box-right-eight-first"><img src="../../assets/rentHouseDetails/phone.png" alt="">{{adminPhone}}</button>
                                <button class="box-right-eight-second" @click="ceshi1()">在线咨询</button>
                            </div>
                        </div>
                        <button class="order" style="border-right: 2px solid white;" @click="close1 ">预约看房</button>
                        <button class="rent" @click=" close">我要租房</button>
                        <div class="floating-frame">
                            <div>欢迎使用<br>临安国资小程序</div>
                            <img style="width: 250px;" src="../../assets/home/qrCode.png" alt="">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        <!-- 弹框 -->
        <el-dialog
            title="我要租房"
            :visible.sync="centerDialogVisible"
            width="550px"
            center>
            <div class="detsils-dialog">
                <div style="margin-bottom:20px;text-align: center;"> 
                    <div class="detsils-dialog-text">承租主体类别：</div>
                    <div class="detsils-dialog-content">
                        <el-select style="width:350px" v-model="value" placeholder="请选择">
                            <el-option
                               v-for="item in options"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                            </el-option>
                        </el-select>
                    </div>   
                </div>
                <div v-if="value == 'GT' ">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
                        <el-form-item label="姓名：" prop="name">
                            <el-input  v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="电话：" prop="phone">
                            <el-input  v-model="ruleForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号：" prop="IDnumber">
                            <el-input  v-model="ruleForm.IDnumber"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证照片：" prop="IDcard">
                             <el-upload
                                class="avatar-uploader"
                                name="multipartFile"
                                action="https://api.lagzxl.com/o-r-e-u/ability-merchant/upload/file"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess1">
                                <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                                <img v-else src="../../assets/home/route.png" alt="">
                            </el-upload>
                             <el-upload
                                class="avatar-uploader"
                                name="multipartFile"
                                action="https://api.lagzxl.com/o-r-e-u/ability-merchant/upload/file"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess2">
                                <img v-if="imageUrl2" :src="imageUrl2" class="avatar">
                                <img v-else src="../../assets/home/route.png" alt="">
                            </el-upload>  
                        </el-form-item>
                        
                    </el-form>
                </div>
                <div v-if="value != 'GT' && value != '' ">
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="170px" class="demo-ruleForm">
                        <el-form-item label="联系人姓名：" prop="name">
                            <el-input  v-model="ruleForm.name"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人电话：" prop="phone">
                            <el-input  v-model="ruleForm.phone"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证号：" prop="IDnumber">
                            <el-input  v-model="ruleForm.IDnumber"></el-input>
                        </el-form-item>
                        <el-form-item label="身份证照片：" prop="IDcard">
                             <el-upload
                                class="avatar-uploader"
                                name="multipartFile"
                                action="https://api.lagzxl.com/o-r-e-u/ability-merchant/upload/file"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess1">
                                <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                                <img v-else src="../../assets/home/route.png" alt="">
                            </el-upload>
                             <el-upload
                                class="avatar-uploader"
                                name="multipartFile"
                                action="https://api.lagzxl.com/o-r-e-u/ability-merchant/upload/file"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess2">
                                <img v-if="imageUrl2" :src="imageUrl2" class="avatar">
                                <img v-else src="../../assets/home/route.png" alt="">
                            </el-upload>  
                        </el-form-item>
                        <el-form-item v-if="value == 'MY' || value == 'GY'" label="企业名称：" prop="">
                            <el-input  v-model="ruleForm.companyName"></el-input>
                        </el-form-item>
                        <el-form-item label="统一社会信用代码：" prop="creditCode">
                            <el-input  v-model="ruleForm.creditCode"></el-input>
                        </el-form-item>
                        <el-form-item label="统一社会信用代码照片：" prop="creditCodeCard">
                            <el-upload
                                class="avatar-uploader"
                                name="multipartFile"
                                action="https://api.lagzxl.com/o-r-e-u/ability-merchant/upload/file"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess3">
                                <img v-if="imageUrl3" :src="imageUrl3" class="avatar">
                                <img v-else src="../../assets/home/route.png" alt="">
                            </el-upload>  
                        </el-form-item>
                        
                    </el-form>
                </div>
                
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="applyForRental">确 定</el-button>
            </span>
        </el-dialog>


        <el-dialog
            title="预约看房"
            :visible.sync="centerDialogVisible1"
            width="550px"
            center>
            <div class="detsils-dialog">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="170px" class="demo-ruleForm">
                        <el-form-item label="租客姓名：" >
                            <el-input  v-model="watchName"></el-input>
                        </el-form-item>
                        <!-- <el-form-item label="电话：" >
                            <el-input  v-model="watchPhone"></el-input>
                        </el-form-item> -->
                        <el-form-item label="看房时间：" >
                            <el-date-picker
                                style="width:330px"
                                v-model="value5"
                                type="datetime"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="备注：" >
                            <el-input
                                type="textarea"
                                :rows="2"
                                placeholder="请输入内容"
                                v-model="textarea">
                            </el-input>
                        </el-form-item>
                        
                        
                    </el-form>
                
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="centerDialogVisible1 = false">取 消</el-button>
                <el-button type="primary" @click="submitAppointment">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Header from '../../components/header.vue'
import request from '../../api/index'
import axios from 'axios';
export default {
    name:'RentHouseDetails',
    data(){
        const checkIdNum = (rule, value, callback) => {
        if (!value) {
          return callback(new Error('身份证不能为空'))
        }
        if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
          callback(new Error('请输入正确的二代身份证号码'))
        } else {
          callback()
        }
      }
        return{
            value5:'',
            watchName:'',
            watchPhone:'',
            textarea:'',
            list_img: [],
            activeName: 'first',
            centerDialogVisible: false,
            centerDialogVisible1: false,
            roomId:'',
            turnType:'',
            roomType:'',
            collect:'',
            location:'',
            adminPhone:'',
            roomPrice:'',
            area:'',
            roomName:'',
            estateId:'',
            estateName:'',
            communityName:'',
            floor:'',
            water:'',
            electricity:'',
            facilities:'',
            facArry:'',
            trait:'',
            details:'',
            // location:'',
            searchContent:'',
            cdeAddressX:'',
            cdeAddressY:'',
            nearbyHousesList:[],
            options: [{
                value: 'GT',
                label: '自然人'
                }, {
                value: 'GS',
                label: '个体工商户'
                }, {
                value: 'MY',
                label: '民营企业'
                }, {
                value: 'GY',
                label: '国营企业'
                }],
            value: '',
        // 申请数据
            ruleForm: {
                name:'',
                IDnumber:'',
                creditCode:'',
                phone:'',
                companyName:'' ,
            },
            imageUrl1: '',
            imageUrl11: '',
            imageUrl2: '',
            imageUrl22: '',
            imageUrl3: '',
            imageUrl33: '',
            rules: {
                name: [
                    { required: true, message: '请输入姓名名称', trigger: 'blur' },
                    { min: 1, max: 12, message: '长度在 1 到 12 个字符', trigger: 'blur' }
                ],
                phone: [
                    {required: true,message: "手机号不能为空",trigger: "blur"},
                    {pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,message: "请输入正确的手机号码",trigger: "blur"}],

                // phone: [
                //     { required: true, message: '请输入联系方式', trigger: 'blur' },
                //     { min: 1, max: 11, message: '长度在 1 到 11 个字符', trigger: 'blur' }
                // ],
                IDnumber: [
                    {required: true,message: "身份证号码不能为空",trigger: "blur"},
                    {validator: checkIdNum,trigger: 'blur'}, ],

                // IDnumber: [
                //     { required: true, message: '请输入身份证号号', trigger: 'blur' },
                //     { min: 1, max: 18, message: '长度在 1 到 18 个字符', trigger: 'blur' }
                // ],
                creditCode: [
                    { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
                    { min: 1, max: 12, message: '长度在 1 到 12 个字符', trigger: 'blur' }
                ],
                
            },
          swiperOptionTop: {
            autoplay:true,    //开启无限轮播
            spaceBetween:10,
            // zoom: true,//双击放大
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
          },
          swiperOptionThumbs: {
            loop:true,    //开启无限轮播
            // autoplay:true,    //开启无限轮播
            spaceBetween: 10,	//小图之间得距离
            direction: 'horizontal',
            grabCursor: false, // 抓手,
            watchSlidesVisibility: true, // 防止不可点击
            slidesPerView: 'auto' ,   //显示几个图片
            centeredSlides: true, // 设置活动块居中
            slideToClickedSlide: true,

          }
        }
    },

  updated() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.swiper
      const swiperThumbs = this.$refs.swiperThumbs.swiper
      swiperTop.controller.control = swiperThumbs
      swiperThumbs.controller.control = swiperTop
    })
  },
    mounted() {
        document.documentElement.scrollTop=0;
        this.getCurrentHouseInformation()
        // this.initMap()
    },
    methods:{
        close1(){
            if(JSON.parse(localStorage.getItem('userId'))){
                this.centerDialogVisible1 = true
            }else{
                this.$message('请先登录');
            }
        },
        close(){
            if(JSON.parse(localStorage.getItem('userId'))){
                this.centerDialogVisible = true
            }else{
                this.$message('请先登录');
            }
        },
        ceshi1(){

           ysf('product', {
            //    sendByUser : 1,
                show : 1, // 1为打开， 其他参数为隐藏（包括非零元素）
                // {{roomName}} {{roomType}} {{turnType}}
                title : this.estateName +'/'+ this.roomName +'/' + this.roomType,
                desc : this.roomType +'/'+ this.area +'/' + this.turnType,
                picture : this.list_img[0],
                note : this.roomPrice + '元/月',
                url : 'https://api.lagzxl.com/#/rentHouseDetails?roomId='+this.roomId,
                
                success: function(){     // 成功回调
                    ysf('open');
                },
                error: function(){       // 错误回调
                    // handle error
                }
            })
        },
        // 申请租房
        applyForRental(){
            
            if (this.ruleForm.name == "") {
                this.$message({ type: "warning", message: "姓名不能为空" });
                return;
            }
             if (this.ruleForm.phone == "") {
                this.$message({ type: "warning", message: "联系电话不能为空" });
                return;
            }
             if (this.ruleForm.IDnumber == "") {
                this.$message({ type: "warning", message: "身份证号码不能为空" });
                return;
            }
             if (this.ruleForm.imageUrl1 == "") {
                this.$message({ type: "warning", message: "身份证正面不能为空" });
                return;
            }
            if (this.ruleForm.imageUrl2 == "") {
                this.$message({ type: "warning", message: "身份证后面不能为空" });
                return;
            }
            if(this.value == "MY" || this.value == "GY"){
                if (this.ruleForm.imageUrl3 == "") {
                    this.$message({ type: "warning", message: "统一社会信用代码照片不能为空" });
                    return;
                }
                if (this.ruleForm.creditCode == "") {
                    this.$message({ type: "warning", message: "统一社会信用代码不能为空" });
                    return;
                }
            }
            console.log(this.location)
            let params = {
                    userId: JSON.parse(localStorage.getItem('userId')), 
                    userName: this.ruleForm.name, 
                    userPhone:this.ruleForm.phone,
                    roomId: this.roomId,
                    estateId: this.estateId,
                    location: this.location,
                    roomName: this.roomName,
                    estateType : "Z",
                    idNumber : this.ruleForm.IDnumber,
                    numberPic : this.imageUrl11 +"," +this.imageUrl22,
                    infoType : this.value,
                    companyName : this.ruleForm.companyName,
                    ceditCode : this.ruleForm.creditCode,
                    ceditPic : this.imageUrl33,
            }
                    request.post('/operating-real-estate/user/add-appoint',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                            this.$message({
                                message: '提交成功',
                                type: 'success'
                            });
                        }
                        }
                    );
            this.centerDialogVisible = false;
        },
        // 预约看房
        submitAppointment() {
        console.log(this.value5)
        let time = this.time(this.value5)
        console.log(time)
        let params = {
            roomId: this.roomId,
            estateId: this.estateId,
            leaseUserId: JSON.parse(localStorage.getItem('userId')),
            leaseUserName: this.watchName,
            leaseUserPhone: this.watchPhone,
            startTime: time,
            remark: this.textarea,
        }
        axios.post('https://api.lagzxl.com/o-r-e-app/business-apartment/index/add-purpose', params)
            .then(res =>{
                if (res.result == 0) {
                // 成功之后
                this.$message({
                    message: '预约成功',
                    type: 'success'
                 });
            }
            })
            .catch(function (reason) {
                console.log(reason);
            });
            this.centerDialogVisible1 = false;
    },
    time(date) {
			var y = date.getFullYear()
			var m = date.getMonth() + 1
			m = m < 10 ? '0' + m : m
			var d = date.getDate()
			d = d < 10 ? '0' + d : d
			var h = date.getHours()
			h = h < 10 ? '0' + h : h
			var minute = date.getMinutes()
			minute = minute < 10 ? '0' + minute : minute
			var second = date.getSeconds()
			second = second < 10 ? '0' + second : second
			return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
        },
        // 上传图片
        handleAvatarSuccess1(res, file) {
            this.imageUrl11 = res.data.tempPath;    
            this.imageUrl1 = URL.createObjectURL(file.raw);
        },

        handleAvatarSuccess2(res, file) {
            this.imageUrl22 = res.data.tempPath;
            this.imageUrl2 = URL.createObjectURL(file.raw);
        },
        handleAvatarSuccess3(res, file) {
            this.imageUrl33 = res.data.tempPath;
            this.imageUrl3 = URL.createObjectURL(file.raw);
        },
        // 获取当前房屋信息
        getCurrentHouseInformation(){
            this.roomId = this.$route.query.roomId;
            console.log(this.roomId)
            let params = {
                    "roomId" : this.roomId,
                    "userId" : JSON.parse(localStorage.getItem('userId'))  
            }
                    request.post('/operating-real-estate/home/roomDetails',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                if(res.data.turnType == "EAST"){
                                this.turnType = "朝东";
                             }else if(res.data.turnType == "SOUTH"){
                                this.turnType = "朝南";
                             }else if(res.data.turnType == "WEST"){
                                this.turnType = "朝西";
                             }else if(res.data.turnType == "NORTH"){
                                this.turnType = "朝北";
                             }
                            //  this.turnType = res.data.turnType;
                             this.roomType = res.data.roomType;
                             this.collect = res.data.collect;
                             this.location = res.data.location;
                             this.roomPrice =res.data.roomPrice;
                             this.area = res.data.area;
                             this.floor = res.data.floor;
                             this.list_img = res.data.roomPics;
                             this.adminPhone = res.data.adminPhone;
                             this.details = res.data.details;
                             this.estateId = res.data.estateId;
                            //  this.location = res.data.location;
                             this.roomName = res.data.roomName;
                             this.trait = res.data.trait;
                             this.estateName = res.data.estateName;
                             this.communityName = res.data.communityName;
                             this.water = res.data.water;
                             this.electricity = res.data.electricity;
                             this.facilities = res.data.facilities;
                             this.facArry = this.facilities.split(',');
                             this.cdeAddressX = res.data.cdeAddressX;
                             this.cdeAddressY = res.data.cdeAddressY;

                             this.getNearbyHouses(res.data.cdeAddressX,res.data.cdeAddressY)
                             this.initMap(res.data.cdeAddressX,res.data.cdeAddressY)
                        }
                        }
                    );
                    
                      
        },
        // 获取附近房源
        getNearbyHouses(X,Y){
            let params = {
                    "cdeAddressX" : X,
                    "cdeAddressY" : Y,
                    "estateType" : "Z" 
            }
                    request.post('/operating-real-estate/home/getNearbyRoom',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.nearbyHousesList = res.data
                        }
                        }
                    );  
        },
        // 关注房源或取消关注
        collection(){
            if(JSON.parse(localStorage.getItem('userId'))){
            let params = {
                    "roomId" : this.roomId,
                    "userId" : JSON.parse(localStorage.getItem('userId'))  
            }
                    request.post('/operating-real-estate/user/userCollect',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             console.log(res.result)
                             this.getCurrentHouseInformation()
                        }
                        }
                    ); 
            }else{
                this.$message('请先登录');
            } 
        },
        cancelCollection(){
            if(JSON.parse(localStorage.getItem('userId'))){
                let params = {
                    "roomId" : this.roomId,
                    "userId" : JSON.parse(localStorage.getItem('userId'))  
            }
                    request.post('/operating-real-estate/user/userCollect',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             console.log(res.result)
                             this.getCurrentHouseInformation()
                        }
                        }
                    );  
            }else{
                this.$message('请先登录');
            }
            
        },
        initMap(X,Y) {
            let that = this;
            let map = new AMap.Map('container', {
                resizeEnable: true, //是否监控地图容器尺寸变化
                zoom: 13, //初始化地图层级
                zooms: [10, 20],//缩放范围
                center: [X, Y],//初始化地图中心点
                mapStyle: "amap://styles/fresh"//map样式
            });
            that.map = map;
            //房源标点
            let marker = new AMap.Marker({
                // icon: that.markerIcon.iconHospital,//自定义icon
                position: [X, Y],
            });
            //将marker放在地图上
            marker.setMap(that.map);
            //设置label提示
            marker.setLabel({
            offset: new AMap.Pixel(0, -8),
            content: "<div style='color: #4b89eb'>" + this.estateName +"</div>",
            direction: 'top'
            });
        },





        // 页面锚点
        onToYear1() {
            const a = document.getElementById("year1");
            a.scrollIntoView();
        },
        onToYear2() {
            const a = document.getElementById("year2");
            a.scrollIntoView();
        },
        onToYear3() {
            const a = document.getElementById("year3");
            a.scrollIntoView();
        },
        onToYear4() {
            const a = document.getElementById("year4");
            a.scrollIntoView();
        },
    },
    components:{
    Header,
    },

}
</script>
<style lang="less" >
//.gallery-thumbs .swiper-wrapper {
//  transform:none !important;
//  transition-duration: 0ms !important;
//}
.thumb-example {
  width: 864px;
  margin-top: 20px;
  // background: #000;
}
.swiper-slide {
  background-size: cover;
  background-position: center;
}
.gallery-top {
  // height: 80% !important;
  height: 600px;
  width: 100%;
}
.gallery-thumbs {
  height: 20% !important;
  box-sizing: border-box;
  padding: 10px 0px;
  width: 864px;
  margin-left: 2px;
  .swiper-button-next {
    right: 0px;
  }
  .swiper-button-prev {
    left: 0px;
  }
  .swiper-button-next,
  .swiper-button-prev {
    background: #fff;
    width: 45px;
    text-align: center;
    height: 101px;
    top: 26%;
    div {
      margin-top: 30px;
      background: rgb(207, 205, 205);
      height: 45px;
      border-radius: 50%;
      img {
        margin: 7px 0 0 2px;
        width: 30px;
      }
    }
  }
  .swiper-button-next:hover div {
    background: rgb(189, 186, 186);
  }
  .swiper-button-prev:hover div {
    background: rgb(189, 186, 186);
  }
}
.gallery-thumbs .swiper-slide {
  width: 20%;
  height: 80px;
  // opacity: 0.4;
}
.gallery-thumbs .swiper-slide-active {
  border: 2px solid red;
}


.avatar{
        width: 120px;
        height: 100px;
    }
    .choose{
        width: 100%;
        height: 220px;
    }
    .house-data{
        display: inline-block;
        flex: 6;
    }
    .house-data-first{
        // width: 400px;
        // height: 24px;
        font-size: 24px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 24px;
        margin: 40px 0px 20px 0px;
    }
    .house-data-second{
        // width: 264px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
    }
    .follow-data{
        display: inline-block;
        flex: 4;
    }
    .follow-data > img{
        margin: 30px 0px 8px 4px;
    }
    .follow-data > div{
        width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }
    .detailed-data{
        width: 100%;
        height: 100%;
        background: #F2F3F8;
    }
    .detailed-data-box{
        width: 1276px;
        height: 100%;
        margin: 0 auto;
        text-align: left;
        display: flex;
        flex-direction: row;
    }
    // 左部分
    .detailed-data-box-left{
        flex: 7;
        margin-top:34px
    }
    .small img {
    width: 100%;
    height: 100%;
    }
    .house-details-data-text{
        width: 120px;
        height: 30px;
        font-size: 30px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        margin: 40px 0px 20px 0px;
    }
    .detailed-information{
        display: flex;
        flex-direction: row;
        margin-bottom: 1px;
        background: #FFFFFF ;
    }
    .detailed-information-first{
        flex: 2;
        width: 80px;
        height: 20px;
        font-size: 20px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        margin: 20px 0px 0px 20px;
        vertical-align: top;
    }
    .detailed-description{
        flex: 8;
        padding-top: 20px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
    }
    .supporting-facilities{
        flex: 8;
        padding-top: 20px;
        
    }
    .supporting-facilities-ul{
        width: 390px;
    }
    .supporting-facilities-ul > li{
        display: inline-block;
    }
    .supporting-facilities-li > div{
        display: inline-block;
        margin-right: 30px;
    }
    .existence{
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        text-align: center;
    }
    .not-existence{
        text-align: center;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        text-decoration:line-through;
    }
    .information-box{
        display: inline-block;
        width: 220px;
        margin-bottom: 20px;
    }
    .information-name{
        width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-right: 20px;
    }
    .information-content{
        width: 42px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }


    .house-details-img-li{
        display: inline-block;
    }
    .house-details-img-li > img{
        width: 205px;
        height: 180px;
        margin: 0px 15px 20px 0px;
    }




    .nearby-houses-ul > li{
        background-color: white;
        width:270px;
        display: inline-block;
    }
    .nearby-houses-img{
        width: 270px;
        height: 180px;
        margin-right: 15px;
    }
    .nearby-houses-text-line{
        
        height: 18px;
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 18px;
        margin: 20px 0px 37px 10px;
    }
    .nearby-houses-describe{
        display: inline-block;
        // width: 70px;
        height: 20px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin: 0px 0px 15px 0px;

    }
    .nearby-houses-price{
        display: inline-block;
        // width: 39px;
        height: 20px;
        font-size: 20px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #E76565;
        line-height: 20px;
    }
    .nearby-houses-company{
        display: inline-block;
        width: 30px;
        height: 20px;
        font-size: 12px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #E76565;
        line-height: 20px;
    }




    // 右部分
    .detailed-data-box-right{  
        flex: 3;
        margin-left: 50px;
        
    }
    .data-box-right-first1{
        width: 360px;
        height: 460px;
        background: #FFFFFF;
        border-radius: 6px;
        margin: 34px 0px 20px 0px;
        padding-top: 20px;
    }
    .detailed-data-box-right > button{
        display: inline-block;
        width: 180px;
        height: 60px;
        background: #3094FF;
        margin: 0 auto;
        font-size: 18px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 60px;
        border: none;
    }
    .order{
        border-radius: 6px 0px 0px 6px;
    }
    .rent{
        border-radius: 0px 6px 6px 0px;
    }
    .box-right-one-first{
        margin: 0px 0px 20px 20px;
        display: inline-block;
        // width: 100px;
        height: 40px;
        font-size: 40px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #E76565;
        line-height: 40px;
    }
    .box-right-one-second{
        display: inline-block;
        width: 35px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #E76565;
        line-height: 14px;
        margin-left: 10px;
    }
    .box-right-two,.box-right-three,.box-right-five,.box-right-six,.box-right-eight{
        margin: 0px 0px 15px 20px;
    }
    .box-right-two > span{
        width: 94px;
        height: 20px;
        font-size: 20px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        margin-right: 35px;
    }
    .box-right-three > span{
        width: 94px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        margin-right: 30px;
    }
    .box-right-four{
        margin-bottom: 40px;
    }
    .box-right-five-first{
        width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-right: 50px;
    }
    .box-right-five-second{
        width: 182px;
        // height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
    }
    .box-right-seven{
        margin: 0px 0px 40px 20px;
    }
    .box-right-eight-first{
        width: 200px;
        height: 51px;
        background: #3094FF;
        border-radius: 8px;
        border: none;
        font-size: 22px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 51px;
        margin-right: 15px;
    }
    .box-right-eight-second{
        width: 105px;
        height: 51px;
        border-radius: 8px;
        border: 1px solid #3094FF;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 51px;
    }
    .detsils-dialog-text{
        display: inline-block;
        width: 98px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }
    .detsils-dialog-content{
        display: inline-block;
        
    }
</style>