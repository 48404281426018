// 引入vue
import Vue from 'vue';
// 引入vue-router
import VueRouter from 'vue-router';
// 注册 第三方库需要use一下才能用
Vue.use(VueRouter)
// 引入HelloWorld页面
import Home from '../views/home'
import RentHouseSearch from '../views/rentHouseSearch'
import LeaseShopSearch from '../views/leaseShopSearch'
import shopping from '../views/shopping'
import shoppingList from '../views/shoppingList'
import RentHouseDetails from '../views/rentHouseDetails'
import LeaseShopDetails from '../views/leaseShopDetails'
import CulturalTourismList from '../views/culturalTourismList'
import CulturalTourismDetails from '../views/culturalTourismDetails'
import PersonalHomepage from '../views/personalHomepage'
import RentShopsDetails from '../views/rentShopsDetails'
import IndustrialParkDetails from '../views/industrialParkDetails'
import IndustrialParkSearch from '../views/industrialParkSearch'
import workRoomSearch from '../views/workRoomSearch'
import workRoomDetails from '../views/workRoomDetails'
import rentAuctionList from '../views/rentAuctionList'
import Map from '../views/map'




// 定义routes路由的集合，数组类型
const routes=[
    //单个路由均为对象类型，path代表的是路径，component代表组件
    {path:'/',redirect:"/home"},
    {path:'/home',name:"Home",component:Home,meta:{keepAlive:true}},
    {path:'/workRoomSearch',name:"workRoomSearch",component:workRoomSearch,},
    {path:'/rentAuctionList',name:"rentAuctionList",component:rentAuctionList},
    {path:'/workRoomDetails',name:"workRoomDetails",component:workRoomDetails},
    {path:'/rentHouseSearch',name:"RentHouseSearch",component:RentHouseSearch}, 
    {path:'/leaseShopSearch',name:"LeaseShopSearch",component:LeaseShopSearch}, 
    {path:'/culturalTourismList',name:"CulturalTourismList",component:CulturalTourismList}, 
    {path:'/culturalTourismDetails',name:"CulturalTourismDetails",component:CulturalTourismDetails}, 
    {path:'/rentHouseDetails',name:"RentHouseDetails",component:RentHouseDetails}, 
    {path:'/leaseShopDetails',name:"LeaseShopDetails",component:LeaseShopDetails}, 
    {path:'/personalHomepage',name:"PersonalHomepage",component:PersonalHomepage}, 
    {path:'/rentShopsDetails',name:"RentShopsDetails",component:RentShopsDetails}, 
    {path:'/industrialParkDetails',name:"IndustrialParkDetails",component:IndustrialParkDetails}, 
    {path:'/industrialParkSearch',name:"IndustrialParkSearch",component:IndustrialParkSearch}, 
    {path:'/map',component:Map}, 

    {path:'/shoppingMall',name:"RentHouseDetails",component:shopping}, 
    
    {path:'/shoppingList',name:"shoppingList",component:shoppingList}, 
    {
        path:'/myBill',
        component: PersonalHomepage,
        redirect:"/myBill/index",
        children:[
         {path: "",name:"",component:()=>import("../views/myBill")}
        ]
      },
      {
        path:'/myContract',
        component: PersonalHomepage,
        redirect:"/myContract/index",
        children:[
         {path: "",name:"",component:()=>import("../views/myContract")}
        ]
      },
      {
        path:'/myTracks',
        component: PersonalHomepage,
        redirect:"/myTracks/index",
        children:[
         {path: "",name:"",component:()=>import("../views/myTracks")}
        ]
      },
    
]

// 实例化VueRouter并将routes添加进去
const router = new VueRouter({
// ES6简写，等于routes：routes
    routes,
    scrollBehavior(to,from,savePosition){
      if(savePosition){
        return savePosition
      }else {
        return {x:0,y:0}
      }
    }
});






// 抛出这个这个实例对象方便外部读取以及访问
export default router
