<template>
    <el-main class="main">
        <!--路由的出口-->
        <router-view></router-view>
    </el-main>
    
</template>

<script>
export default {
    name:"main.vue",
    data(){
        return{
            
        }
    },
}
</script>
<style lang="less" scoped>
    .main{
        padding: 0px;
        margin: 30px 0px 0px 20px;
        background:#FFFFFF;
    }
</style>