<template>
    <div>
        <div style="width:100%;min-width: 1500px; height:70px;background:#292929 ;">
            <Header></Header>
        </div>
        <div style="width:100%;min-width: 1500px;">
            <div class="choose">
                <div class="box">
                    <div>
                        <input class="chiose-input" v-model="searchContent" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" type="text">
                            <div class="btn">
                                <img @click="$router.push({name:'RentHouseSearch',params:{searchContent:searchContent}})" src="../../assets/rentHouseSearch/search.png" alt="">
                            </div>
                            <div class="map">
                                <img src="../../assets/rentHouseSearch/map.png" alt="">
                                <span>地图找房</span>
                            </div>
                    </div>
                    <div style="display:flex;flex-direction: row;">
                        <div class="house-data">
                            <div class="house-data-first">锦北街道 碧桂苑 {{roomType}} {{turnType}}</div>
                            <div class="house-data-second">租房 / 锦北街道 / 碧桂苑 / 当前房源</div>
                        </div>
                        <div class="follow-data">
                            <img @click="cancelCollection" v-if="collect" src="../../assets/rentHouseDetails/follow.png" alt="">
                            <img @click="collection" v-else src="../../assets/rentHouseDetails/not-follow.png" alt="">
                            <div>关注房源</div>
                        </div>  
                    </div>  
                </div>
            </div>
            <div class="detailed-data">
                <div class="detailed-data-box">
                    <div class="detailed-data-box-left">
                        <div>
                            <el-carousel height="500px" indicator-position="outside" :autoplay="false" :loop="false">
                                <el-carousel-item>
                                    <h3 class="small">
                                        <img src="http://robooot.oss-cn-hangzhou.aliyuncs.com/youKe-dir/I6IOE9I28EU77UO8MCBL42K2JB.png" alt />
                                    </h3>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div >
                             <el-tabs v-model="activeName" >
                                <el-tab-pane name="first">
                                    <div style="color:black" slot="label" @click="onToYear1">房屋信息</div>
                                </el-tab-pane>
                                <el-tab-pane name="second">
                                    <div style="color:black" slot="label" @click="onToYear2">房屋图片</div>
                                </el-tab-pane>
                                <el-tab-pane name="third">
                                    <div style="color:black" slot="label" @click="onToYear3">周边位置</div>
                                </el-tab-pane>
                                <el-tab-pane name="fourth">
                                    <div style="color:black" slot="label" @click="onToYear4">附近房源</div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                        <div class="house-details-data">
                            <div id="year1" class="house-details-data-text">房屋信息</div>
                            <div class="house-details-data-box">
                                <div class="detailed-information">
                                    <div class="detailed-information-first">基本信息</div>
                                    <div style="flex:8;padding-top: 20px;">
                                         <div class="information-box">
                                             <span class="information-name">面积</span>
                                             <span class="information-content">70㎡</span>
                                         </div>
                                         <div  class="information-box">
                                             <span class="information-name">朝向</span>
                                             <span class="information-content">朝南</span>
                                         </div>
                                         <div  class="information-box">
                                             <span class="information-name">楼层</span>
                                             <span class="information-content">7/14</span>
                                         </div>
                                         <div  class="information-box">
                                             <span class="information-name">电梯</span>
                                             <span class="information-content">有</span>
                                         </div>
                                         <div  class="information-box">
                                             <span class="information-name">用水</span>
                                             <span class="information-content">民用水</span>
                                         </div>
                                         <div class="information-box">
                                             <span class="information-name">用电</span>
                                             <span class="information-content">民用电</span>
                                         </div>
                                         <div style="margin-bottom:20px">
                                             <span class="information-name">装修</span>
                                             <span class="information-content">精装修</span>
                                         </div>
                                         <div style="margin-bottom:20px">
                                             <span class="information-name">房性特点</span>
                                             <ul style="display:inline-block" class="text-end-ul">
                                                <li>交通便利</li>
                                                <li>电梯房</li>
                                            </ul>
                                         </div>
                                    </div>
                                </div>
                                <div class="detailed-information">
                                    <div class="detailed-information-first">配套设施</div>
                                    <div class="supporting-facilities">
                                        <ul class="supporting-facilities-ul">
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑空调.png" alt="">
                                                    <div class="not-existence">空调</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/空调.png" alt="">
                                                    <div class="existence">空调</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑冰箱.png" alt="">
                                                    <div class="not-existence">冰箱</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/冰箱.png" alt="">
                                                    <div class="existence">冰箱</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑电视.png" alt="">
                                                    <div class="not-existence">电视</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/电视.png" alt="">
                                                    <div class="existence">电视</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑洗衣机.png" alt="">
                                                    <div class="not-existence">洗衣机</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/洗衣机.png" alt="">
                                                    <div class="existence">洗衣机</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/黑热水器.png" alt="">
                                                    <div class="not-existence">热水器</div>
                                                </div>
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/热水器.png" alt="">
                                                    <div class="existence">热水器</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑衣柜.png" alt="">
                                                    <div class="not-existence">衣柜</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/衣柜.png" alt="">
                                                    <div class="existence">衣柜</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑床.png" alt="">
                                                    <div class="not-existence">床</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/床.png" alt="">
                                                    <div class="existence">床</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑微波炉.png" alt="">
                                                    <div class="not-existence">微波炉</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/微波炉.png" alt="">
                                                    <div class="existence">微波炉</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑桌椅.png" alt="">
                                                    <div class="not-existence">桌椅</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/桌椅.png" alt="">
                                                    <div class="existence">桌椅</div>
                                                </div>
                                            </li>
                                            <li class="supporting-facilities-li">
                                                <div v-show="false">
                                                    <img src="../../assets/rentHouseDetails/黑电磁炉.png" alt="">
                                                    <div class="not-existence">电磁炉</div>
                                                </div>
                                                <div v-show="true">
                                                    <img src="../../assets/rentHouseDetails/电磁炉.png" alt="">
                                                    <div class="existence">电磁炉</div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="detailed-information">
                                    <div class="detailed-information-first">详情描述</div>
                                    <div class="detailed-description">
                                        详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述
                                        详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述
                                        详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述
                                        详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述详情描述
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="house-details-img">
                            <div id="year2" class="house-details-data-text">房屋图片</div>
                            <div>
                                <ul>
                                    <li class="house-details-img-li"><img src="http://robooot.oss-cn-hangzhou.aliyuncs.com/youKe-dir/I6IOE9I28EU77UO8MCBL42K2JB.png" alt=""></li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div id="year3" class="house-details-data-text">周边位置</div>
                            <div id="container" style="width:100%;height:350px;"></div>
                        </div>
                        <div>
                            <div id="year4" class="house-details-data-text">附近房源</div>
                            <div class="nearby-houses">
                                <ul class="nearby-houses-ul">
                                    <li v-for="item in nearbyHousesList" style="margin-right: 15px;">
                                        <router-link class="link" target="_blank" :to="{path:'/rentHouseDetails',query: { roomId: item.roomId}}">
                                            <div><img class="nearby-houses-img" src="../../assets/home/model.png" alt=""></div>
                                            <div class="nearby-houses-text">镇北街道  碧桂园</div>
                                            <div>
                                                <div class="nearby-houses-describe">{{item.roomType}}</div>
                                                
                                                <div class="nearby-houses-company">元/月</div>
                                                <div class="nearby-houses-price">{{roomPrice}}</div>
                                            </div>
                                        </router-link>
                                        
                                    </li>
                                    <!-- <li>
                                        <div><img class="nearby-houses-img" src="../../assets/home/model.png" alt=""></div>
                                        <div class="nearby-houses-text">镇北街道  碧桂园</div>
                                        <div>
                                            <div class="nearby-houses-describe">2室2厅1卫</div>
                                            <div class="nearby-houses-price">6666</div>
                                            <div class="nearby-houses-company">元/月</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div><img class="nearby-houses-img" src="../../assets/home/model.png" alt=""></div>
                                        <div class="nearby-houses-text">镇北街道  碧桂园</div>
                                        <div>
                                            <div class="nearby-houses-describe">2室2厅1卫</div>
                                            <div class="nearby-houses-price">6666</div>
                                            <div class="nearby-houses-company">元/月</div>
                                        </div>
                                    </li>
                                    <li>
                                        <div><img class="nearby-houses-img" src="../../assets/home/model.png" alt=""></div>
                                        <div class="nearby-houses-text">镇北街道  碧桂园</div>
                                        <div>
                                            <div class="nearby-houses-describe">2室2厅1卫</div>
                                            <div class="nearby-houses-price">6666</div>
                                            <div class="nearby-houses-company">元/月</div>
                                        </div>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                    <div class="detailed-data-box-right">
                        <div class="data-box-right-first">
                            <div class="shop-box-right-one">
                                <span class="shop-box-right-one-first">距离结束</span>
                                <count-down class="shop-box-right-one-second" :endTime="endTime" endText="已经结束了" />
                            </div>
                            <div class="shop-box-right-two">
                                <span class="shop-box-right-one-first">当前价格</span>
                                <span class="shop-box-right-two-second">3300</span>
                                <span class="shop-box-right-two-third">元/月</span>
                            </div>
                            <div class="shop-box-right-three">
                                <span class="shop-box-right-one-first">我的出价</span>
                                <el-input-number v-model.trim="num" :step="100"></el-input-number>
                            </div>
                            <div class="shop-box-right-four">
                                <button>立即报名(保证金￥200)</button>
                            </div>
                            <div class="shop-box-right-five">
                                <el-tooltip content="Bottom center" placement="bottom" effect="light">
                                    <span><img style="width:14px;height:14px;vertical-align:middle;" src="../../assets/rentShopsDetails/_问号小.png" alt="">如何交钱报名</span>
                                </el-tooltip>
                            </div>
                            <hr style="background: #E7E7E7;margin-bottom:20px">
                            <div class="shop-box-right-six">
                                <ul class="shop-box-right-six-ul">
                                    <li class="shop-box-right-six-li">
                                        <div class="shop-box-right-six-li-first">￥200</div>
                                        <div class="shop-box-right-six-li-second">保证金</div>
                                    </li>
                                    <li class="shop-box-right-six-li">
                                        <div class="shop-box-right-six-li-first">￥3300</div>
                                        <div class="shop-box-right-six-li-second">起始价</div>
                                    </li>
                                    <li class="shop-box-right-six-li">
                                        <div class="shop-box-right-six-li-first">￥100</div>
                                        <div class="shop-box-right-six-li-second">加价幅度</div>
                                    </li>
                                    <li class="shop-box-right-six-li">
                                        <div class="shop-box-right-six-li-first">5分钟/轮</div>
                                        <div class="shop-box-right-six-li-second">延时报价</div>
                                    </li>
                                </ul>
                            </div>
                            <div class="shop-box-right-seven">
                                <span style="color:red">*</span>
                                <span class="shop-box-right-seven-text">竞拍成功后押金可抵押部分房租，请在合同生效后支付剩余部分租金</span>
                            </div> 
                        </div>
                        <div class="shop-box-right-eight">
                            <div class="shop-box-right-eight-first">231次围观</div>
                            <div class="shop-box-right-eight-second">3人报名</div>
                        </div>
                        
                        <div class="floating-frame">
                            <div>欢迎使用<br>临安国资小程序</div>
                            <img class="qrCode" src="../../assets/home/qrCode.png" alt="">
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </div>
</template>
<script>
import Header from '../../components/header.vue'
import request from '../../api/index'
import countDown from '@/components/countDown'
export default {
    name:'RentHouseDetails',
    data(){
        return{
            list_img: [],
            activeName: 'first',
            centerDialogVisible: false,
            roomId:'',
            turnType:'',
            roomType:'',
            collect:'',
            location:'',
            roomPrice:'',
            area:'',
            floor:'',
            searchContent:'',
            cdeAddressX:'',
            cdeAddressY:'',
            nearbyHousesList:[],
            endTime:'',
            num:3300
        }
    },
    mounted() {
        document.documentElement.scrollTop=0;
        this.getCurrentHouseInformation()
        this.initMap(119.7149,30.236737)
    },
    methods:{
        // 获取当前房屋信息
        getCurrentHouseInformation(){
            this.endTime = new Date("2022/05/25 21:00:00").getTime() / 1000 +  ' '
            this.roomId = this.$route.query.roomId;
            console.log(this.roomId)
            let params = {
                    "roomId" : "1II2M51BJIRN7UT6V8P10V1BS9",
                    "userId" : JSON.parse(localStorage.getItem('userId'))  
            }
                    request.post('/operating-real-estate/home/roomDetails',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                if(res.data.turnType == "EAST"){
                                this.turnType = "朝东";
                             }else if(res.data.turnType == "SOUTH"){
                                this.turnType = "朝南";
                             }else if(res.data.turnType == "WEST"){
                                this.turnType = "朝西";
                             }else if(res.data.turnType == "NORTH"){
                                this.turnType = "朝北";
                             }
                            //  this.turnType = res.data.turnType;
                             this.roomType = res.data.roomType;
                             this.collect = res.data.collect;
                             this.location = res.data.location;
                             this.roomPrice =res.data.roomPrice;
                             this.area = res.data.area;
                             this.floor = res.data.floor;
                             this.list_img = res.data.roomPics;
                             this.cdeAddressX = res.data.cdeAddressX;
                             this.cdeAddressY = res.data.cdeAddressY;
                             this.getNearbyHouses(res.data.cdeAddressX,res.data.cdeAddressY)
                        }
                        }
                    );
                    
                      
        },
        // 获取附近房源
        getNearbyHouses(X,Y){
            let params = {
                    "cdeAddressX" : X,
                    "cdeAddressY" : Y,
                    "estateType" : "Z" 
            }
                    request.post('/operating-real-estate/home/getNearbyRoom',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.nearbyHousesList = res.data
                        }
                        }
                    );  
        },
        // 关注房源或取消关注
        collection(){
            let params = {
                    "roomId" : this.roomId,
                    "userId" :JSON.parse(localStorage.getItem('userId'))  
            }
                    request.post('/operating-real-estate/user/userCollect',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             console.log(res.result)
                             this.getCurrentHouseInformation()
                        }
                        }
                    );  
        },
        initMap(X,Y) {
            let that = this;
            let map = new AMap.Map('container', {
                resizeEnable: true, //是否监控地图容器尺寸变化
                zoom: 13, //初始化地图层级
                zooms: [10, 20],//缩放范围
                center: [X, Y],//初始化地图中心点
                mapStyle: "amap://styles/fresh"//map样式
            });
            that.map = map;
            //房源标点
            let marker = new AMap.Marker({
                // icon: that.markerIcon.iconHospital,//自定义icon
                position: [X, Y],
            });
            //将marker放在地图上
            marker.setMap(that.map);
            //设置label提示
            marker.setLabel({
            offset: new AMap.Pixel(0, -8),
            content: "<div style='color: #4b89eb'>" + this.estateName +"</div>",
            direction: 'top'
            });
        },
        cancelCollection(){
            let params = {
                    "roomId" : this.roomId,
                    "userId" : JSON.parse(localStorage.getItem('userId'))  
            }
                    request.post('/operating-real-estate/user/userCollect',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             console.log(res.result)
                             this.getCurrentHouseInformation()
                        }
                        }
                    );  
        },


        // 页面锚点
        onToYear1() {
            const a = document.getElementById("year1");
            a.scrollIntoView();
        },
        onToYear2() {
            const a = document.getElementById("year2");
            a.scrollIntoView();
        },
        onToYear3() {
            const a = document.getElementById("year3");
            a.scrollIntoView();
        },
        onToYear4() {
            const a = document.getElementById("year4");
            a.scrollIntoView();
        },
    },
    components:{
    Header,countDown,
    }
}
</script>
<style lang="less">
    .choose{
        width: 100%;
        height: 220px;
    }
    .house-data{
        display: inline-block;
        flex: 6;

    }
    .house-data-first{

        font-size: 24px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 26px;
        margin: 30px 0px 10px 0px;
    }
    .house-data-second{
        
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 16px;
    }
    .follow-data{
        display: inline-block;
        flex: 4;
    }
    .follow-data > img{
        margin: 30px 0px 8px 4px;
    }
    .follow-data > div{
        width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }
    .detailed-data{
        width: 100%;
        height: 100%;
        background: #F2F3F8;
    }
    .detailed-data-box{
        // width: 1070px;
        width: 1276px;
        height: 100%;
        margin: 0 auto;
        text-align: left;
        display: flex;
        flex-direction: row;
    }
    // 左部分
    .detailed-data-box-left{
        flex: 7;
        margin-top:34px
    }
    .small img {
    width: 100%;
    height: 100%;
    }
    .house-details-data-text{
        width: 120px;
        height: 30px;
        font-size: 30px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        margin: 40px 0px 20px 0px;
    }
    .detailed-information{
        display: flex;
        flex-direction: row;
        margin-bottom: 1px;
        background: #FFFFFF ;
    }
    .detailed-information-first{
        flex: 2;
        width: 80px;
        height: 20px;
        font-size: 20px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
        margin: 20px 0px 0px 20px;
        vertical-align: top;
    }
    .detailed-description{
        flex: 8;
        padding-top: 20px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
    }
    .supporting-facilities{
        flex: 8;
        padding-top: 20px;
        
    }
    .supporting-facilities-ul{
        width: 500px;
        margin: 0;
    }
    .supporting-facilities-ul > li{
        display: inline-block;
    }
    .supporting-facilities-li > div{
        display: inline-block;
        margin-right: 40px;
        margin-bottom: 20px;
    }
    .existence{
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
        text-align: center;
    }
    .not-existence{
        text-align: center;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        text-decoration:line-through;
    }
    .information-box{
        display: inline-block;
        width: 220px;
        margin-bottom: 10px;
    }
    .information-name{
        width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-right: 20px;
    }
    .information-content{
        width: 42px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
    }


    .house-details-img-li{
        display: inline-block;
    }
    .house-details-img-li > img{
        width: 205px;
        height: 180px;
        margin: 0px 15px 20px 0px;
    }




    .nearby-houses-ul > li{
        display: inline-block;
    }
    .nearby-houses-img{
        width: 270px;
        height: 180px;
        margin-right: 15px;
    }
    .nearby-houses-text{
        
       
        font-size: 18px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        margin: 20px 0px 20px 10px;
    }
    .nearby-houses-describe{
        display: inline-block;
        // width: 70px;
        height: 20px;
        font-size: 10px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin: 0px 0px 15px 10px;

    }
    .nearby-houses-price{
        // display: inline-block;
        // width: 39px;
        height: 20px;
        font-size: 16px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #E76565;
        line-height: 20px;
        float:right;
        
    }
    .nearby-houses-company{
        // display: inline-block;
        width: 30px;
        height: 20px;
        font-size: 8px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #E76565;
        line-height: 20px;
        float:right;
        
    }




    // 右部分
    .detailed-data-box-right{  
        flex: 3;
        margin-left: 50px;
        
    }
    .data-box-right-first{
        width: 360px;
        height: 500px;
        background: #FFFFFF;
        border-radius: 6px;
        margin: 34px 0px 20px 0px;
        padding-top: 20px;
    }
    .shop-box-right-four > button{
        display: block;
        width: 320px;
        height: 60px;
        background: #3094FF;
        border-radius: 6px;
        margin: 10px auto;
        font-size: 18px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 60px;
        border: none;
    }
    .shop-box-right-one-first{
        margin: 0px 50px 20px 20px;
        display: inline-block;
        width: 56px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
    }
    .shop-box-right-one-second{
        display: inline-block;
        // width: 21px;
        height: 20px;
        font-size: 20px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 20px;
    }
    .shop-box-right-two-second{
        width: 77px;
        height: 40px;
        font-size: 40px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #E76565;
        line-height: 40px;
    }
    .shop-box-right-two-third{
        width: 35px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #E76565;
        line-height: 14px;
    }
    
    .shop-box-right-five{
        height: 12px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #E76565;
        line-height: 12px;
        text-align: right;
        margin-bottom: 21px;
    }
    .shop-box-right-six-li{
        display: inline-block;
        margin-left: 30px;
        margin-bottom: 20px;
        text-align: center;
    }
    .shop-box-right-six-li-first{
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
        margin-bottom: 10px;
    }
    .shop-box-right-six-li-second{
        height: 12px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
    }
    .shop-box-right-seven{
        padding-left: 20px;
    }
    .shop-box-right-seven-text{
        width: 310px;
        height: 40px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
    .shop-box-right-eight{
        width: 360px;
        height: 63px;
        background: #FFFFFF;
        border-radius: 6px;
        display: flex;
        flex-direction: row;
    }
    .shop-box-right-eight-first{
        flex: 5;
        // width: 26px;
        height: 63px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 63px;
        border-right: 1px solid #E7E7E7;
        text-align: center;
    }
    .shop-box-right-eight-second{
        flex: 5;
        // width: 26px;
        height: 63px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: hsl(0, 0%, 20%);
        line-height: 63px;
        text-align: center;
    }
    
</style>