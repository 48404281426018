<template>
    <div>
        <div style="width:100%;min-width: 1500px; height:70px;background:#292929 ;">
            <Header :isActive="7"></Header>
        </div>
        <div style="height:90vh;padding: 0px 200px;width: 1200px;margin: 0 auto;">
            <div style="height:90vh;" v-html="details">

            </div>
        </div>
        
    </div>
</template>

<script>
import Header from '../../components/header.vue'
import request from '../../api/index'
export default {
    data() {
        return {
            id:'',
            details:'',
        }
    },
    mounted(){
        this.getCulturalTourismDetails()
    },
    methods:{
      getCulturalTourismDetails(){
          this.id = this.$route.query.id;
          let params = { 
               "id" : this.id,  
                    }
                    request.get('/operating-real-estate/home/getAttractionInfo',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.details = res.data.details
                            }
                        }
                    ); 
      }  
    },
     components:{
        Header,
    }
}
</script>

<style>

</style>