<template>
    <div class="main-header">
            <div class="header-nav">
                <ul class="nav-ul">
                    <li class="nav-li-first">
                        <img style="width: 119px;height: 34px;vertical-align: middle;" src="../assets/home/gzLogo.png" alt="">
                    </li>
                    
                    <li class="nav-li" v-for="(item,index) in navList" 
                        v-bind:class="{active:item.id===isActiveLocal}"
                        @click="checkedItem(item.id)">{{item.name}}</li>
                    <!-- <li class="nav-li" @click="ceshi">首页</li>
                    <li class="nav-li"><router-link class="link" to="/rentHouseSearch">房产租赁</router-link></li>
                    <li class="nav-li">旺铺拍租</li>
                    <li class="nav-li"><router-link class="link" to="/industrialParkSearch">招商引资</router-link></li>
                    <li class="nav-li">资产拍卖</li>
                    <li class="nav-li"><router-link class="link" to="/culturalTourismList">文化旅游</router-link></li>
                    <li class="nav-li">幸福家园</li>
                    <li class="nav-li">小程序</li> -->


                    <li v-if="as" class="nav-li-end">
                        <!-- <div style="display:inline-block;margin-right: 31px;">
                            <el-badge style="height:24px;line-height:24px" :value="3" class="item">
                                <img src="../assets/home/提醒.png" alt="">
                            </el-badge>
                        </div> -->
                        <router-link class="link" to="/personalHomepage">
                            <div style="display:inline-block;">
                                <img style="vertical-align: middle;margin-right: 10px;width: 40px;height: 40px;border-radius: 50%;" :src="userInfo.headPic" alt="">
                                <span class="user-phone">{{phone}}</span>
                            </div>
                        </router-link>
                        
                        <div style="display:inline-block;">
                            <span class="sign-out" @click="logout()">退出</span>
                        </div> 
                    </li>
                    <li v-else class="nav-li-end">
                        <el-popover
                            width="160"
                            trigger="click">
                            <div style="width:160px;height:190px;">
                                <!-- <img :src="'data:image/Jpeg;base64,'+imgurl" alt=""> -->
                                <div style="text-align:center">微信扫码</div>
                                <div style="text-align:center">在小程序中登录</div>
                                <img style="width:160px;height:160px" :src="imgurl" alt="">
                            </div>
                        <el-button class="nav-li-btn" slot="reference" @click="ceshi()">登录/注册</el-button>
                        </el-popover>
                    </li>



                    <!-- <li class="nav-li-end">
                        <div style="display:inline-block;margin-right: 31px;">
                            <el-badge style="height:24px;line-height:24px" :value="3" class="item">
                                <img src="../assets/home/提醒.png" alt="">
                            </el-badge>
                        </div>
                        <router-link class="link" to="/personalHomepage">
                            <div style="display:inline-block;">
                            <img style="vertical-align: middle;margin-right: 10px;" src="../assets/home/头像.png" alt="">
                            <span class="user-phone">137****1234</span>
                        </div>
                        </router-link>
                        
                        <div style="display:inline-block;">
                            <span class="sign-out">退出</span>
                        </div> 
                    </li> -->
                </ul>
            </div>
    </div>
</template>

<script>
    import request from '../api/index'
    export default {
        // <router-link class="link" to="/home">首页</router-link>
        name: "header.vue",
        data() {
            return {
                isActiveLocal: this.isActive,
                imgurl:'',
                only:'',
                userId:'',
                userInfo:'',
                phone:'',
                as:false,
                navList:[{
                    name:"首页",
                    id:1,
                },{
                    name:"房产租赁",
                    id:2,
                },{
                    name:"资产处置",
                    id:3,
                },{
                    name:"招商引资",
                    id:4,
                },{
                    name:"文化旅游",
                    id:7,
                },{
                    name:"共富家园",
                    id:8,
                }
                ]
            }
        },
        props:{
            isActive:{
                type:Number,
                default:0
            }
        },
        watch:{
            isActive(val){
                console.log(777,val);
                this.isActiveLocal = val
            }
        },
        mounted(){
            if(localStorage.getItem('userId')){
                // this.as = true
                this.getUserInfo()
            }else{
            //    this.as = false 
            }
        },
       
        
        methods: {
            checkedItem(index){
                 if(index  == 1){   
                    this.$router.push('/Home')
                }else if(index  == 2){
                    this.$router.push('/RentHouseSearch')
                }else if(index  == 3){
                    this.$router.push('/rentAuctionList')
                }else if(index  == 4){
                    this.$router.push('/IndustrialParkSearch')
                }else if(index  == 7){
                    this.$router.push('/CulturalTourismList')
                }else if(index  == 8){
                    this.$router.push('/shoppingList')
                }else {
                    this.open1()
                }
                

                
            },
            open1() {
                this.$message('还在开发中');
            },
            ceshi(){
                let params = { }
                    request.get('/operating-real-estate/home/getQrCode',params)
                    .then(
                        res=>{
                            if(res.result == 0){
                                this.imgurl = res.data.filePath
                                this.only = res.data.only
                                console.log(this.imgurl)
                                console.log(this.only)
                                this.timer = setInterval(this.get, 3000);
                            }
                        }
                    ); 
                
            },
            get(){
                console.log(this.only)
                let params = { 
                       "only" : this.only
                    }
                    request.post('/operating-real-estate/user/iSLogin',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.as = true
                             localStorage.setItem('userId', JSON.stringify(res.data))
                            
                             clearInterval(this.timer);
                             this.getUserInfo()
                            }
                        }
                    );
            },
             getUserInfo(){
           
            let params = {
                "userId" :  JSON.parse(localStorage.getItem('userId'))
            }
            // console.log(params.userId)
            request.get('/operating-real-estate/user/getUserInfo',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                                this.userInfo = res.data
                                this.as = true
                               this.phone = this.userInfo.phone.replace(/^(\d{3})\d{4}(\d{4})/,'$1****$2')
                               localStorage.setItem('phone', this.userInfo.phone)
                            }
                        }
                    );
        },
            logout(){
                this.as = false
                localStorage.clear()
            },
        },
    }
</script>

<style lang="less">
    .main-header{
        width: 1200px !important;
        
        height: 70px;
        margin: 0 auto;
        .header-nav {
            text-align: left;
        }
        
    }
    .nav-ul,.picture-ul,ul{
        margin: 0;
        padding: 0;
        list-style:none;
    }
    .nav-ul > li{
        
        float: left;
        cursor:pointer;
    }
   
    .link{
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .nav-li-first{
        height: 70px;
        line-height: 70px;
        position: absolute;
        left: 50px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .nav-li{
        margin-right: 20px;
        margin-top: 10px;
        width: 100px;
        text-align: center;
        height: 50px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
         color: #FFFFFF;
        line-height: 50px;
        transition: background-color 0.2s ease-in-out;
    }
    .nav-ul .nav-li:nth-child(2) {
        margin-left: -40px ;
    }
    .nav-li:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
    .active{
        height: 50px;
        line-height: 50px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .nav-li-end{
        float:right !important;
        margin-left: 3%;
        height: 70px;
        line-height: 70px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .nav-li-btn{
        background: none;
        border: 1px solid #fff;
        height: 40px;
        border-radius: 8px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #FFFFFF;
    }
    .user-phone{
        width: 81px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 14px;
        margin-right: 30px;
    }
    .sign-out{
        width: 28px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
         color: #FFFFFF;
        line-height: 14px;
    }
</style>