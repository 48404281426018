<template>
    <div style="width: 100%;height: 100%;background: #F2F3F8">
        <div style="width:100%;min-width: 1500px; height:70px;background:#292929 ;">
            <Header></Header>
        </div>
        <div style="width:100%;min-width: 1500px;height: 100%;">
            <div class="box">
                <el-container>
                    <Aside></Aside>
                    <Main></Main>
                </el-container>
            </div>
            
        </div>
    </div>
</template>

<script>
import Header from '../../components/header.vue'
import Aside from './components/Aside.vue'
import Main from './components/Main.vue'
export default {
    name: "PersonalHomepage",
    data() {
        return {
            
        }
    },
     components:{
        Header,
        Aside,
        Main,
    }
    
}
</script>

<style lang="less" scoped>
    .box{
        width: 1070px;
        height: 100%;
        margin: 0 auto;
        text-align: left;
    }
</style>