<template>
    <div>
        
        <div style="width:100%;min-width:1520px; height:70px;background:#292929 ;">
            <Header :isActive="3"></Header>
        </div>
        <div style="width:100%;min-width: 1500px;min-height: calc(100vh - 70px)" id="loadingContainer">
           <div class="chiose2">
               <div class="box">
                   <div>
                       <input v-model="searchContent" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" class="chiose-input" type="text" placeholder="请输入资产名称" >
                        <div class="btn">
                            <img @click="search" src="../../assets/rentHouseSearch/search.png" alt="">
                        </div>
                   </div>
                   <div class="condition" style="height:32px">
                       <span style="margin-top: 12px;">类型</span>
                        <ul style="vertical-align:top" class="condition-ul">
                            <li class="li-btn1" v-for="(item,index) in typeList" 
                                v-bind:class="{active2:item.id==isActive}"
                                @click="checkedItem(item.id,item.type)">{{item.name}}</li>
                        </ul>
                   </div>
                   <!-- <div class="condition" style="height:60px">
                       <span style="margin-top: 12px;">区域</span>
                        <ul class="condition-ul">
                            <li  v-for="item in streetList"><el-button class="li-btn" @click="changeStreet(item.name)" type="text">{{item.name}}</el-button></li>
                        </ul>
                   </div>    -->
               </div>
           </div>
           <div class="show">
               <div class="box show-box">
                   <div style="flex:8.5">
                       <div class="show-text">已为您找到<span>{{ totalCount }}</span>套拍租资产</div>
                        <!-- <div style="margin-bottom:32px">
                            <ul class="show-nav-ul">
                                <li>综合排序</li>
                                <li @click="newArrival">最新上架</li>
                                <li class="show-nav-li-end">
                                    价格排序
                                    <img @click="fromLowToHigh" v-if="sort" src="../../assets/rentHouseSearch/lower.png" alt="">
                                    <img @click="fromHeightToLow" v-else src="../../assets/rentHouseSearch/up.png" alt="">
                                </li>
                            </ul>
                        </div> -->
                        <!-- <router-link class="link" to="/rentHouseDetails"> -->
                            <div>
                                <ul class="three-box">
                                    <li v-for="item in auctionList" class="part-three-li" :key="item.id">
                                        <div  @click="ceshi(item.auctionId)">
                                            <div style="height:100%;">
                                                <div class="link-first-box-line" >
                                                    <img class="part-three-li-img" :src="item.picUrl" alt="">
                                                    <div v-if="item.newStatusCode == 2" class="auction-tips1">正在进行</div>
                                                    <div v-if="item.newStatusCode == 1" class="auction-tips2">即将开始</div>
                                                    <div v-if="item.newStatusCode == 3" class="auction-tips3">已结束</div>
                                                    <div v-if="item.newStatusCode == -6" class="auction-tips3">暂缓</div>
                                                    <div v-if="item.newStatusCode == -5" class="auction-tips3">撤拍</div>
                                                    <div v-if="item.newStatusCode == -4" class="auction-tips3">中止</div>
                                                    <div v-if="item.newStatusCode == -3" class="auction-tips3">撤回</div>
                                                    <div v-if="item.newStatusCode == -2" class="auction-tips3">流拍</div>
                                                    <div v-if="item.newStatusCode == -1" class="auction-tips3">取消</div>
                                                    <div v-if="item.newStatusCode == 0" class="auction-tips3">未发布</div>
                                                </div>
                                                <div class="link-second-box">
                                                    <div class="link-second-box-one">{{item.title}}</div>
                                                    <div class="link-second-box-two">
                                                        <span class="area-text">面积</span>
                                                        <span class="area-number">{{item.constructionArea?item.constructionArea+'㎡':'无'}}</span>
                                                    </div>
                                                    <div>
                                                        <div class="describe">当前价格</div>
                                                        <div class="company">元</div>
                                                        <div class="price">{{item.currentPrice / 100}}</div>
                                                        
                                                    </div>
                                                    <div class="link-second-box-three">
                                                        <span class="time-text">截止时间</span>
                                                        <span class="time-number">{{item.endTime}}</span>
                                                    </div>
                                                    <div class="link-third-box">
                                                        <div class="watch"><span class="sign-text">{{item.lookUserNum}}</span>次围观</div>
                                                        <div class="sign"><span class="sign-text">{{item.applyCount}}</span>人报名</div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </li>    
                                </ul>
                                
                            </div>
                        <!-- </router-link> -->
                   </div>
                    <div class="floating-frame">
                        <div>欢迎使用<br>临安国资小程序</div>
                        <img class="qrCode" src="../../assets/home/qrCode.png" alt="">
                    </div>
               </div>
           </div>
           
           <div style="height: 60px;background: #F2F3F8;">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalCount">
                </el-pagination>
            </div>
    
    
        </div>   
    </div>
        
        
    </template>
    <script>
    import Header from '../../components/header.vue'
    import {Loading} from 'element-ui'
    import request from '../../api/index'
    export default {
        name:'RentAuctionList',
        data(){
            return{
                typeList:[{
                    name:'全部',
                    type:'',
                    id:0
                },{
                    name:"房产",
                    id:1,
                    type:'123564002'
                },{
                    name:"其他资产",
                    type:'123582002',
                    id:2,
                }],
                isActive:0,
                searchContent:'',
                loading:'',
                roomType:'',
                auctionList:[],
                
                sort:true,
                streetList:[
                {name:'不限'},
                {name:'锦城街道'},
                {name:'锦北街道'},
                {name:'锦南街道'},
                {name:'青山湖街道'},
                {name:'玲珑街道'},
                {name:'高虹镇'},
                {name:'板桥镇'},
                {name:'太湖源镇'},
                {name:'天目山镇'},
                {name:'於潜镇'},
                {name:'太阳镇'},
                {name:'昌化镇'},
                {name:'河桥镇'},
                {name:'湍口镇'},
                {name:'清凉峰镇'},
                {name:'龙岗镇'},
                {name:'岛石镇'},
                {name:'潜川镇'},
    
                ],
                roomPriceMin:'',
                roomPriceMax:'',
                location:'',
                roomName:'',
                // 分页信息
                // 总条数
                totalCount:null,
                totalCount1:null,
                totalCount2:null,
                // 页数
                pageNum:null,
                // 每页多少条
                pageRow:null,
                url:'https://zc-item.taobao.com/auction.htm',
            }
        },
        mounted() {
            // this.searchContent = this.$route.params.searchContent;
            this.searchListings()
            
        },
        methods:{
            ceshi(id){
                window.open(this.url + "?spm=a213w.7629120.projectDetail.4.5e185c459WTKZB" + "&id=" + id, '_blank')
                // window.location.href = this.url + "?spm=a213w.7629120.projectDetail.4.5e185c459WTKZB" + "&id=" + id;
            },
            // 改变类型
            checkedItem(index,type){
                this.totalCount = 0
                this.isActive=index;
                this.pageNum = 1
                this.roomType = type
                this.searchListings();
            },
            // 改变街道
            changeStreet(val){
                this.location = val
                this.searchListings();
            },
            search(){
                this.roomName = this.searchContent
                this.searchListings();
            },
            
            // 查看标的
            searchListings(){
                var container = document.getElementById('loadingContainer');
                let _that = this
                this.auctionList = []
              let params = {
                    // "roomType":this.roomType,
                    "roomName":this.roomName,
                    "type":this.roomType,
                     "pageNum" : this.pageNum || 1,
                     "pageRow" : this.pageRow || 9,
                }
                _that.loading = Loading.service({
                    target: container,
                    text:'数据加载中....',
                    spinner:'el-icon-loading',
                    background:'rgba(0,0,0,0.7)'
                })
                request.post('/operating-real-estate/home/aliAuctionList',params)
                    .then(
                        res=>{
                            _that.loading.close()
                            console.log(this.isActive)
                            if (res.result == 0) {
                                console.log(777,res.data.list.length);
                                if(res.data.list.length != 0){
                                    this.auctionList =  [...res.data.list]
                                    this.totalCount = res.data.totalCount;
                                }else{
                                    this.auctionList = []
                                    this.totalCount = 0
                                }
                            }
                        }
                    );
           }, 
        
        //    最新上架
           newArrival(){
               let params = {
                   "newTime" : "DESC"
               }
               request.post('/operating-real-estate/home/searchRoom',params)
                        .then(
                            res=>{
                                if (res.result == 0) {
                                 this.houseList = res.data.list;
                                  this.totalCount = res.data.totalCount;
                                }
                            }
                        );
           },
        //    价格排序
        //    fromLowToHigh(){
        //        this.sort = !this.sort
        //        let params = {
        //              "orderBy" : "ASC",
        //         }
        //                 request.post('/operating-real-estate/home/searchRoom',params)
        //                 .then(
        //                     res=>{
        //                         if (res.result == 0) {
        //                          this.houseList = res.data.list;
        //                           this.totalCount = res.data.totalCount;
        //                         }
        //                     }
        //                 );
        //    },
        //    fromHeightToLow(){
        //        this.sort = !this.sort
        //        let params = {
        //              "orderBy" : "DESC",
        //         }
        //                 request.post('/operating-real-estate/home/searchRoom',params)
        //                 .then(
        //                     res=>{
        //                         if (res.result == 0) {
        //                          this.houseList = res.data.list;
        //                           this.totalCount = res.data.totalCount;
        //                         }
        //                     }
        //                 );
        //    },
    
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                if(val == 10){
                    this.pageRow = 5; 
                }else if(val == 20){
                    this.pageRow = 10;
                }else if(val == 30){
                    this.pageRow = 15;
                }else if(val == 40){
                    this.pageRow = 20;
                }
                this.searchListings();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.pageNum = val;
                this.searchListings();
            },
            // getTotalChange(type){
            //     if(type == 1){
            //         if (this.roomPriceMin < 0) {
            //             this.$message({
            //                 type: 'warning',
            //                 message: '租金最小值不能小于0'
            //             })
            //             this.roomPriceMin = 0
            //         }
            //     }else{
            //         if (this.roomPriceMax < 0 || this.roomPriceMax < this.roomPriceMin) {
            //             this.$message({
            //                 type: 'warning',
            //                 message: '租金最大值不能小于0，且不能小于最小值'
            //             })
            //             this.roomPriceMax = 0
            //         }
            //     }
                
                
            // }
        },
        components:{
        Header,
        }
    }
    </script>
    
    <style lang="less" scoped>
        
        .chiose2{
            width: 100%;
            height: 180px;
        }
        .box{
            width: 1070px;
            height: 100%;
            margin: 0 auto;
            text-align: left;
        }
        .chiose-input{
            width: 645px;
            height: 60px;
            background: #F2F3F8;
            padding: 0px;
            font-size: 16px;
            border-radius: 8px 0px 0px 8px;
            // border: 1px solid #121111;
            border: none;
            outline:none;
            padding: 0px 0px 0px 25px;
            vertical-align: top;
            margin-top:30px;
        }
        .btn{
            display: inline-block;
            width: 100px;
            height: 60px;
            line-height: 60px;
            background: #F2F3F8;
            text-align: center;
            // border: 1px solid #121111;
            border: none;
            border-radius: 0px 8px 8px 0px;
            margin-top:30px
        }
        .btn > img{
            vertical-align: middle;
        }
        .condition{
            height: 32px;
            line-height: 32px;
            margin: 30px 0;
        }
        .condition-ul{
            display: inline-block;
            width: 1000px;
        }
        .condition-ul > li{
            display: inline-block;
            margin-right: 30px;
        }
        .li-btn{
            width: 64px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
        }
        .li-btn1{
            width: 64px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #333333;
            line-height: 16px;
            margin-top: 12px;
            margin-left: 17px;
        }
        .active2{
            width: 64px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #409EFF;
            line-height: 16px;
            margin-top: 12px;
            margin-left: 17px;
        }
        .condition > span{
            display: inline-block;
            margin-right: 30px;
            width: 32px;
            height: 16px;
            font-size: 16px;
            font-family: PingFang-SC-Semibold, PingFang-SC;
            font-weight: 600;
            color: #333333;
            line-height: 16px;
            vertical-align: top;
        }
        .show{
            width: 100%;
            // height: 500px;
            background: #F2F3F8;
            display: flex;
            flex-direction: row;
        }
        .show-box{
            display: flex;
            flex-direction: row;
        }
        .floating-frame{
            text-align: left;
            flex: 1.5;
            margin-top: 44px;
        }
        .floating-frame > div{
            width: 112px;
            height: 46px;
            font-size: 16px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
        }
        .show-text{
            // width: 385px;
            height: 30px;
            font-size: 30px;
            font-family: PingFang-SC-Semibold, PingFang-SC;
            font-weight: 600;
            color: #333333;
            line-height: 30px;
            padding: 40px 0px 0px 0px;
            margin-bottom: 40px;
        }
        .show-text > span{
            color: #3094FF ;
        }
        .show-nav-ul > li{
            display: inline-block;
            width: 110px;
            height: 18px;
            font-size: 18px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #999999;
            line-height: 18px;
            margin-right: 50px;
            cursor:pointer;
        }
        .show-nav-li-end > img{
            display: inline-block;
        }
        .three-box > li{
            display: inline-block;
            width: 268px;
            height: 395px;
            background: #FFFFFF;
            border-radius: 8px;
            border: 1px solid #ECECEC;
            margin-right: 20px;
            margin-bottom: 20px;
        }
        .part-three-li-img{
            width:268px;
            height:180px
        }
        .auction-tips1{
            background-image: url(../../assets/home/underway.png);
        }
        .auction-tips2{
            background-image: url(../../assets/home/start.png);
        }
        .auction-tips3{
            background-image: url(../../assets/home/over.png);
        }
        .link-first-box-line {
            position: relative;
        }
        .auction-tips1,.auction-tips2,.auction-tips3{
            width: 70px;
            height: 25px;
            font-size: 12px;
            font-family: PingFang-SC-Semibold, PingFang-SC;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 25px;
            text-align: center;
            position: absolute;
            top: 155px;
            left: 198px ;
        }
        .link-second-box{
            width:268px;
            height:210px
        }
        .link-second-box-one{
            // width: 119px;
            height: 50px;
            font-size: 18px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            margin: 20px 0px 15px 20px;
        }
        .link-second-box-two,.link-second-box-three{
            margin: 0px 0px 8px 20px;
        }
        .area-text{
            width: 28px;
            height: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
            margin-right: 16px;
        }
        .area-number{
            // width: 40px;
            height: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #333333;
            line-height: 14px;
            float: right;
            margin-right: 8px;
        }
        .describe{
            display: inline-block;
            width: 100px;
            height: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
            margin: 0px 0px 15px 20px;
        }
        .company{
            display: inline-block;
            // width: 30px;
            height: 20px;
            font-size: 12px;
            font-family: PingFang-SC-Medium, PingFang-SC;
            font-weight: 500;
            color: #E76565;
            line-height: 20px;
            float: right;
        }
        .price{
            display: inline-block;
            // width: 39px;
            height: 20px;
            font-size: 20px;
            font-family: DINAlternate-Bold, DINAlternate;
            font-weight: bold;
            color: #E76565;
            line-height: 20px;
            float: right;
            margin-right: 5px;
        }
        .time-text{
            width: 56px;
            height: 14px;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
            margin-right: 8px;
        }
        .time-number{
            // width: 93px;
            height: 20px;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
            float: right;
            margin-right: 8px;
        }
        .link-third-box{
            height: 42px;
            border-top: 1px solid #ECECEC;
            display: flex;
            flex-direction: row;
        }
        .watch,.sign{
            flex: 5;
            height: 42px;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #999999;
            line-height: 42px;
            text-align: center;
        }
        .watch{
            border-right: 1px solid #ECECEC;
        }
        .sign-text{
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 600;
            color: #333333;
            line-height: 14px;
        }



    </style>
    