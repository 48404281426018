<template>
<div>
    
    <div style="width:100%;min-width:1520px; height:70px;background:#292929 ;">
        <Header :isActive="4"></Header>
    </div>
    <div style="width:100%;min-width: 1500px;">
       <div class="chiose">
           <div class="box">
               <div>
                   <input v-model="searchContent" onkeyup="this.value=this.value.replace(/(^\s*)|(\s*$)/g,'')" class="chiose-input" placeholder="请输入产业园名称" type="text">
                    <div class="btn">
                        <img @click="searchListings" src="../../assets/rentHouseSearch/search.png" alt="">
                    </div>
                    <!-- <div class="map">
                        <img src="../../assets/rentHouseSearch/map.png" alt="">
                        <span @click="$router.push('/map')">地图找房</span>
                    </div> -->
               </div>
               <div class="condition" style="height:60px">
                   <span style="margin-top: 12px;">区域</span>
                    <ul class="condition-ul">
                        <li  v-for="item in streetList"><el-button @click="queryArea(item.name)" :class="{'li-btn': true,'hostIndex':isActiveIndex == item.name }"  type="text">{{item.name}}</el-button></li>
                        <!-- <li><el-button class="li-btn" type="text">文字按钮</el-button></li>
                        <li><el-button class="li-btn" type="text">文字按钮</el-button></li> -->
                    </ul>
               </div>
               <div class="condition">
                   <span style="margin-top: 6px;">租金</span>
                    <el-radio-group v-model="radio1" @change="searchListings" style="display:inline-block">
                        <el-radio label="">不限</el-radio>
                        <el-radio label="1">&lt;3元/天/㎡</el-radio>
                        <el-radio label="2">3-5元/天/㎡</el-radio>
                        <el-radio label="3">5-7元/天/㎡</el-radio>
                        <el-radio label="4">7-10元/天/㎡</el-radio>
                        <el-radio label="5">&gt;10元/天/㎡</el-radio>
                    </el-radio-group>
                    <!-- <div style="display:inline-block;text-align: center;margin-left: 30px;">
                        <input class="price-inpt" v-model="roomPriceMin" type="text"> - <input class="price-inpt" v-model="roomPriceMax" type="text">
                        <el-button style="margin-left:30px" @click="queryPrice" type="text">确认</el-button>
                    </div> -->
               </div>
               <!-- <div class="condition">
                   <span style="margin-top: 6px;">面积</span>
                   <el-radio-group v-model="radio2" @change="informationChange" style="display:inline-block">
                        <el-radio label="">不限</el-radio>
                        <el-radio label="1">&lt;20㎡</el-radio>
                        <el-radio label="2">20-50㎡</el-radio>
                        <el-radio label="3">50-100㎡</el-radio>
                        <el-radio label="4">100-300㎡</el-radio>
                        <el-radio label="5">300-500㎡</el-radio>
                        <el-radio label="6">&gt;500㎡</el-radio>
                    </el-radio-group>
               </div> -->
               <!-- <div class="condition">
                   <span>朝向</span>
                   <el-radio-group v-model="radio3" @change="informationChange" style="display:inline-block">
                        <el-radio label="">不限</el-radio>
                        <el-radio label="SOUTH">朝南</el-radio>
                        <el-radio label="WEST">朝西</el-radio>
                        <el-radio label="EAST">朝东</el-radio>
                        <el-radio label="NORTH">朝北</el-radio>
                    </el-radio-group>
               </div> -->
               <!-- <div class="condition">
                   <span style="margin-top: 6px;">装修</span>
                   <el-radio-group v-model="radio4" @change="searchListings" style="display:inline-block">
                        <el-radio label="">不限</el-radio>
                        <el-radio label="HH">豪华</el-radio>
                        <el-radio label="JZ">精装修</el-radio>
                        <el-radio label="JD">简装</el-radio>
                        <el-radio label="MP">毛坯</el-radio>
                    </el-radio-group>
               </div> -->
               
           </div>
       </div>
       <div class="show">
           <div class="box show-box">
               <div style="flex:8.5">
                   <div class="show-text">已为您找到<span>{{ totalCount }}</span>套精选产业园</div>
                    <div style="margin-bottom:32px">
                        <ul class="show-nav-ul">
                            <!-- <li>综合排序</li>
                            <li>最新上架</li> -->
                            <li class="show-nav-li-end">
                                价格排序
                                <img @click="fromLowToHigh" v-if="sort" src="../../assets/rentHouseSearch/lower.png" alt="">
                                <img @click="fromHeightToLow" v-else src="../../assets/rentHouseSearch/up.png" alt="">
                            </li>
                        </ul>
                    </div>
                    <!-- <router-link class="link" to="/rentHouseDetails"> -->
                        <div>
                            <ul class="show-ul">
                                <li style="margin-bottom:20px" v-for="item in houseList">
                                    <router-link class="link" :to="{path:'/industrialParkDetails',query: { roomId: item.id}}">
                                        <div class="house-box">
                                            <img :src="item.industryPic" alt="">
                                            <div class="house-text">
                                                <div class="text-first">{{item.industryName}}</div>
                                                <div class="text-second">
                                                    
                                                    <div class="text-second-first" style="display:inline-block">面积：{{item.roomArea}}</div> 
                                                    <div class="price" style="margin-left:200px">{{item.roomPrice}}</div>
                                                    <div style="margin-left:10px" class="company1">{{item.industryPrice}}元/天/㎡</div>
                                                </div>
                                                <div class="text-end">
                                                    共{{item.roomCount}}套房间
                                                </div>
                                            </div>
                                        </div>
                                    </router-link>
                                    
                                </li>
                            </ul>
                        </div>
                    <!-- </router-link> -->
               </div>
                <div class="floating-frame">
                    <div>欢迎使用<br>临安国资小程序</div>
                    <img class="qrCode" src="../../assets/home/qrCode.png" alt="">
                </div>
           </div>
       </div>
       
       <div style="height: 60px;background: #F2F3F8;">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="1"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount">
            </el-pagination>
        </div>


    </div>   
</div>
    
    
</template>
<script>
import Header from '../../components/header.vue'
import request from '../../api/index'
export default {
    name:'IndustrialParkSearch',
    data(){
        return{
            radio1: '',
            radio2: '',
            // radio3: '',
            radio4: '',
            searchContent:'',
            roomType:'',
            houseList:[],
            sort:true,
            streetList:[
            {name:'不限'},
            {name:'锦城街道'},
            {name:'锦北街道'},
            {name:'锦南街道'},
            {name:'青山湖街道'},
            {name:'玲珑街道'},
            {name:'高虹镇'},
            {name:'板桥镇'},
            {name:'太湖源镇'},
            {name:'天目山镇'},
            {name:'於潜镇'},
            {name:'太阳镇'},
            {name:'昌化镇'},
            {name:'河桥镇'},
            {name:'湍口镇'},
            {name:'清凉峰镇'},
            {name:'龙岗镇'},
            {name:'岛石镇'},
            {name:'潜川镇'},

            ],
            roomPriceMin:'',
            isActiveIndex:'不限',
            roomPriceMax:'',
            location:'',
            // 分页信息
            // 总条数
            totalCount:null,
            // 页数
            pageNum:null,
            // 每页多少条
            pageRow:null,

        }
    },
    mounted() {
        this.searchContent = this.$route.params.searchContent;
        this.roomType = this.$route.params.roomType;
        this.searchListings()
    },
    methods:{
        // 查询价格
        // queryPrice(){
        //    let params = {
        //         "roomPriceMin" : this.roomPriceMin,
        //         "roomPriceMax" : this.roomPriceMax,
        //     }
        //             request.post('/operating-real-estate/home/searchRoom',params)
        //             .then(
        //                 res=>{
        //                     console.log(res.result)
        //                     if (res.result == 0) {
        //                      this.houseList = res.data.list;
        //                      this.totalCount = res.data.totalCount;
        //                     }
        //                 }
        //             ); 
        // },
        // 查询区域
        queryArea(val){
            this.isActiveIndex = val
            if(val == '不限'){
                val = ''
            }
            this.location = val;

            let params = {
                "industryName" : this.searchContent,
                 "location" : val,
                 "industryPriceMin" : this.roomPriceMin,
                "industryPriceMax" : this.roomPriceMax,
                "pageNum" : this.pageNum || 1,
                 "pageRow" : this.pageRow || 10,
            }
                    request.post('/operating-real-estate/home/industrialList',params)
                    .then(
                        res=>{
                            console.log(res.result)
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                            }
                        }
                    );
        },
        // 信息改变查询
        informationChange(){
            this.paramFomat(this.radio1,1)
            // this.paramFomat(this.radio2,2)
            let params = {
                    "industryName" : this.searchContent,
                //   "roomType" : "Z",
                    "location" : this.location,
                    "industryPriceMin" : this.roomPriceMin,
                    "industryPriceMax" : this.roomPriceMax,
                    "pageNum" : this.pageNum || 1,
                     "pageRow" : this.pageRow || 10,
                    // "houseType" : this.radio2,
                    "furnish" : this.radio4,
                    // "towards" : this.radio3,
            }
                    request.post('/operating-real-estate/home/industrialList',params)
                    .then(
                        res=>{
                            console.log(res.result)
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                            }
                        }
                    );
        },
        //参数统一处理
        paramFomat(param,type){
            if(!param){
                this.roomPriceMin='',
                this.roomPriceMax=''
            }else {
                if(type == 1){
                    if (param == 1){this.roomPriceMin='0',this.roomPriceMax='3'} 
                    if (param == 2){this.roomPriceMin='3',this.roomPriceMax='5'} 
                    if (param == 3){this.roomPriceMin='5',this.roomPriceMax='7'} 
                    if (param == 4){this.roomPriceMin='7',this.roomPriceMax='10'} 
                    if (param == 5){this.roomPriceMin='10',this.roomPriceMax='100000000'} 
                }else {
                    if (param == 1){this.roomPriceMin='0',this.roomPriceMax='20'} 
                    if (param == 2){this.roomPriceMin='20',this.roomPriceMax='50'} 
                    if (param == 3){this.roomPriceMin='50',this.roomPriceMax='100'} 
                    if (param == 4){this.roomPriceMin='100',this.roomPriceMax='300'} 
                    if (param == 5){this.roomPriceMin='300',this.roomPriceMax='500'} 
                    if (param == 6){this.roomPriceMin='500',this.roomPriceMax='100000000'} 
                }
            }
        },
        searchListings(){
            this.paramFomat(this.radio1,1)
          let params = {
                "location" : this.location,
                 "industryName" : this.searchContent,
                 "roomType" : this.roomType,
                 "pageNum" : this.pageNum || 1,
                 "pageRow" : this.pageRow || 10,
                 "location" : this.location,
                "industryPriceMin" : this.roomPriceMin,
                "industryPriceMax" : this.roomPriceMax,
                // "houseType" : this.radio2,
                "furnish" : this.radio4,
            }
                    request.post('/operating-real-estate/home/industrialList',params)
                    .then(
                        res=>{
                            console.log(res.result)
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                              
                            }
                        }
                    );
       }, 
       fromLowToHigh(){
           this.sort = !this.sort
           let params = {
                 "industryName" : this.searchContent,
                 "roomType" : this.roomType,
                 "pageNum" : this.pageNum || 1,
                 "pageRow" : this.pageRow || 10,
                 "location" : this.location,
                "industryPriceMin" : this.roomPriceMin,
                "industryPriceMax" : this.roomPriceMax,
                // "houseType" : this.radio2,
                "furnish" : this.radio4,
                 "priceSort" : "ASC",
            }
                    request.post('/operating-real-estate/home/industrialList',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                            }
                        }
                    );
       },
       fromHeightToLow(){
           this.sort = !this.sort
           let params = {
            "industryName" : this.searchContent,
                 "roomType" : this.roomType,
                 "pageNum" : this.pageNum || 1,
                 "pageRow" : this.pageRow || 10,
                 "location" : this.location,
                "industryPriceMin" : this.roomPriceMin,
                "industryPriceMax" : this.roomPriceMax,
                // "houseType" : this.radio2,
                "furnish" : this.radio4,
                 "priceSort" : "DESC",
            }
                    request.post('/operating-real-estate/home/industrialList',params)
                    .then(
                        res=>{
                            if (res.result == 0) {
                             this.houseList = res.data.list;
                              this.totalCount = res.data.totalCount;
                            }
                        }
                    );
       },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.pageRow = val;
            this.searchListings();
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.pageNum = val;
            this.searchListings();
        }
    },
    components:{
    Header,
    }
}
</script>

<style lang="less" scoped>
    .hostIndex{
        color: #3094FF !important;
    }
    .chiose{
        width: 100%;
        height: 280px;
    }
    .box{
        width: 1070px;
        height: 100%;
        margin: 0 auto;
        text-align: left;
    }
    .price{
        display: inline-block;
        // width: 39px;
        height: 20px;
        font-size: 30px;
        font-family: DINAlternate-Bold, DINAlternate;
        font-weight: bold;
        color: #E76565;
        line-height: 20px;
        // float: right;
        margin-right: 5px;
    }
    .chiose-input{
        width: 645px;
        height: 60px;
        background: #F2F3F8;
        padding: 0px;
        font-size: 16px;
        border-radius: 8px 0px 0px 8px;
        // border: 1px solid #121111;
        border-right: none;
        outline:none;
        padding: 0px 0px 0px 25px;
        vertical-align: top;
        margin-top:30px
    }
    .btn{
        display: inline-block;
        width: 100px;
        height: 60px;
        line-height: 60px;
        background: #F2F3F8;
        text-align: center;
        // border: 1px solid #121111;
        border: none;
        border-radius: 0px 8px 8px 0px;
        margin-top:30px
    }
    .btn > img{
        vertical-align: middle;
    }
    .map{
        text-align: center;
        display: inline-block;
        width: 160px;
        height: 54px;
        line-height: 54px;
        cursor:pointer;
        border-radius: 8px;
        border: 1px solid #3094FF;
        margin-left: 135px;
        margin-top:30px
    }
    .map > img{
        vertical-align: middle;
        margin:0px 5px 0px 0px;
    }
    .map > span{
        width: 64px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 16px;
    }
    .condition{
        height: 32px;
        line-height: 32px;
        margin: 30px 0;
    }
    .condition-ul{
        display: inline-block;
        width: 1000px;
    }
    .condition-ul > li{
        display: inline-block;
        margin-right: 30px;
    }
    .li-btn{
        width: 64px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        // color: #333333;
        line-height: 16px;
    }
    .price-inpt{
        display: inline-block;
        width: 60px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #B4B9C8;
        margin: 0;
        padding: 0;
    }
    .condition > span{
        display: inline-block;
        margin-right: 30px;
        width: 32px;
        height: 16px;
        font-size: 16px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 16px;
        vertical-align: top;
    }
    .show{
        width: 100%;
        // height: 500px;
        background: #F2F3F8;
        display: flex;
        flex-direction: row;
    }
    .show-box{
        display: flex;
        flex-direction: row;
    }
    .floating-frame{
        text-align: left;
        flex: 1.5;
        margin-top: 44px;
    }
    .floating-frame > div{
        width: 112px;
        height: 46px;
        font-size: 16px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
    }
    .show-text{
        // width: 385px; 
        height: 30px;
        font-size: 30px;
        font-family: PingFang-SC-Semibold, PingFang-SC;
        font-weight: 600;
        color: #333333;
        line-height: 30px;
        padding: 40px 0px 0px 0px;
        margin-bottom: 40px;
    }
    .show-text > span{
        color: #3094FF ;
    }
    .show-ul{
        margin-bottom: 30px;
    }
    .show-nav-ul > li{
        display: inline-block;
        width: 110px;
        height: 18px;
        font-size: 18px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        margin-right: 50px;
        cursor:pointer;
    }
    .show-nav-li-end > img{
        display: inline-block;
    }
    .house-box > img{
        vertical-align: middle;
        width: 280px;
        height: 180px;
    }
    .house-text{
        display: inline-block;
        vertical-align: middle;
        width: 620px;
        height: 180px;
        background: #FFFFFF;
        border-radius: 4px;
    }
    .text-first{
        // width: 176px;
        height: 24px;
        font-size: 24px;
        font-family: PingFang-SC-Medium, PingFang-SC;
        font-weight: 500;
        color: #333333;
        line-height: 24px;
        margin: 20px 0px 30px 20px;
    }
    .text-second{
        margin: 0px 0px 38px 20px;
    }
    .text-second > img{
        margin-right: 9px;
        vertical-align: middle;
    }
    .text-second-first{
        // width: 195px;
        height: 18px;
        font-size: 18px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        vertical-align: middle;
    }
    .text-end{
        display: inline-block;
        margin-left: 20px;
        font-size: 12px;
        font-family: PingFang-SC-Regular, PingFang-SC;
        font-weight: 400;
        color: #3094FF;
        line-height: 30px;
        width: 70px;
        height: 30px;
        // background: #E8F3FF;
        border-radius: 4px;
        text-align: center;
    }
    .company1{
    display: inline-block;
    // width: 30px;
    height: 12px;
    text-align: right;
    float: right;
    margin-right: 30px;
    font-size: 20px;
    font-family: PingFang-SC-Medium, PingFang-SC;
    font-weight: 500;
    color: #E76565;
    line-height: 12px;
    // margin-left: 30px;
}

</style>
