import Vue from 'vue'
import App from './App.vue'
// 引入elementui
import ElementUI from 'element-ui';
import 'swiper/dist/css/swiper.min.css'
import VueAwesomeSwiper from "vue-awesome-swiper";
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'
import router from "./router"
import store from "./store"

// 使用elementui
Vue.use(ElementUI, {locale})
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
